/** модальное окно для выбора тем в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import adminAPI from "redux/api/admin";

import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatQuotesTopicsModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Contents.DailyQuotesTopics" />}
      {...props}
      head={(searchTopics, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="Contents.name" />
            </th>
            <th>
              <IntlMessages id="Contents.category" />
            </th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(topic, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!topic.enabled ? "text-strike" : ""}>
            {topic.name}
          </td>
          <td>{topic.category.name}</td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatTopics = (props) => {
  return <FloatQuotesTopicsModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/contents/daily_quotes_topics"),
})(FloatTopics);
