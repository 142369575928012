import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { GendersSelector, ZodiacSelector } from "components/standart";
import { setOnChange } from "components/special";
import adminAPI from "redux/api/admin";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const AddContent = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [newContent, setNewContent] = useState({});
  const [formDisabled, setFormDisabled] = useState(null);
  const changeNewContent = setOnChange(newContent, setNewContent);
  const toggleModal = () => setOpenModal(!openModal);
  const dispatch = useDispatch();
  const { contentForm } = props;

  const addHandler = () => {
    const onSuccess = (body) => {
      dispatch(push(contentForm(body)));
    };
    props.addContent({ body: newContent }, { onSuccess });
  };

  useEffect(() => {
    setFormDisabled(
      !(
        /* newContent.gender1 && newContent.gender2 && */ (
          newContent.astroSign1 && newContent.astroSign2
        )
      )
    );
  }, [newContent]);

  useEffect(() => {
    setNewContent({});
  }, [openModal]);

  return (
    <>
      <i className="fa fa-plus-square" onClick={toggleModal} />

      {openModal && (
        <Modal isOpen={openModal} centered size="md" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="Contents.Add" />
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <IntlMessages id="Genders" /> #1
                </div>
                <div className="col">
                  <GendersSelector
                    value={newContent.gender1}
                    name="gender1"
                    setValue={changeNewContent}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col">
                  <IntlMessages id="AstroSigns" /> #1
                </div>
                <div className="col">
                  <ZodiacSelector
                    value={newContent.astroSign1}
                    name="astroSign1"
                    setValue={changeNewContent}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col">
                  <IntlMessages id="Genders" /> #1
                </div>
                <div className="col">
                  <GendersSelector
                    value={newContent.gender2}
                    name="gender2"
                    setValue={changeNewContent}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col">
                  <IntlMessages id="AstroSigns" /> #2
                </div>
                <div className="col">
                  <ZodiacSelector
                    value={newContent.astroSign2}
                    name="astroSign2"
                    setValue={changeNewContent}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <button
                type="button"
                className="form-control c-primary"
                disabled={formDisabled}
                onClick={addHandler}
              >
                <IntlMessages id="save" />
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addContent: api.post(apiUrls.astroOverlaps),
})(AddContent);
