import _ from "lodash";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useRouteMatch } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

import { connect } from "react-redux";
import { Alert } from "reactstrap";
import ReactQuill from "react-quill";
import IntlMessages from "util/intlMessages";
import { PageTitle } from "components/standart";
import { LoadingIcon, setOnChange } from "components/special";
import { Uploader, ImagePreview } from "components/standart";
import { adminAPI } from "redux/api";

import { apiUrls } from "./init";

const { api } = adminAPI.actions;
const Landings = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs } = props;
  const { envStore } = props;
  const [localeId, setLocaleId] = useState(envStore.locales[0]?._id);

  const [landings, setLandings] = useState({});
  useEffect(() => {
    props.getData(
      { query: { localeId } },
      {
        onRequest() {
          setLandings({});
          setDataSaved(false);
        },
        onSuccess(body) {
          setLandings(body);
        },
      }
    );
  }, [localeId]);

  breadCrumbs.push({
    title: <IntlMessages id="Www.Landing" />,
    url,
  });

  const [dataSaved, setDataSaved] = useState(false);

  const successAlertRef = useRef(null);

  const changeLandings = setOnChange(landings, setLandings);

  const saveSettingsHandler = () => {
    props.saveData(
      {
        query: { localeId },
        body: { ...landings },
      },
      {
        onSuccess(body) {
          setLandings(body);
          setDataSaved(true);
          successAlertRef.current.scrollIntoView();
        },
      }
    );
  };

  const uploadFilesHandler =
    (file) =>
    ([fileData]) => {
      changeLandings({ target: { name: file, value: fileData._id } });
    };

  return (
    <>
      <PageTitle {...breadCrumbs} />

      <Nav tabs style={{ cursor: "pointer" }}>
        {_.map(envStore.locales, (locale, index) => {
          return (
            <Fragment key={index}>
              <NavItem>
                <span
                  className={`nav-link ${localeId === locale._id ? "active" : ""}`}
                  onClick={() => setLocaleId(locale._id)}
                >
                  {locale.name}
                </span>
              </NavItem>
            </Fragment>
          );
        })}
      </Nav>

      {landings._id ? (
        <div className="row">
          <div className="col-12 -col-lg-6">
            <div className="roe-card-style">
              <div className="roe-card-header"></div>
              <div className="roe-card-body">
                <div ref={successAlertRef}>
                  <Alert
                    className="alert-success"
                    isOpen={dataSaved}
                    toggle={() => setDataSaved(false)}
                  >
                    <IntlMessages id="saved" />
                  </Alert>
                </div>

                {[
                  "intro",
                  "score",
                  "info",
                  "shop",
                  "block1",
                  "block2",
                  "block3",
                  "technologies",
                  "technologies2",
                  "technologies3",
                  "mission",
                ].map((name, index) => {
                  const title = `${name}.title`;
                  const content = `${name}.content`;
                  const file = `${name}.fileId`;
                  return (
                    <div key={index}>
                      <h3>
                        <IntlMessages id={`Www.Landing.${name}`} />
                      </h3>
                      <div className="form-group row" key={index}>
                        <label className="col-4 col-form-label">
                          <IntlMessages id={`Www.Landing.title`} />
                        </label>
                        <div className="col-8">
                          <input
                            type="text"
                            name={title}
                            value={_.get(landings, title) || ""}
                            onChange={changeLandings}
                            className="form-control react-form-input"
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div className="form-group row" style={{ height: 250 }}>
                        <label className="col-4 col-form-label">
                          <IntlMessages id={`Www.Landing.content`} />
                        </label>
                        <div className="col-8">
                          <ReactQuill
                            value={_.get(landings, content) || ""}
                            style={{ height: 200 }}
                            onChange={(value) => {
                              changeLandings({ target: { name: content, value } });
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          <IntlMessages id="Www.Landing.image" />
                          <Uploader
                            className="btn-success btn-sm pull-right"
                            successHandler={uploadFilesHandler(file)}
                            imagesOnly="true"
                            multiple={false}
                          />
                        </label>
                        <div className="col-8">
                          {_.get(landings, file) ? (
                            <ImagePreview
                              file={{ _id: _.get(landings, file) }}
                              deleteHandler={() => {
                                changeLandings({ target: { name: file, value: null } });
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="form-group row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="form-control c-primary"
                      onClick={saveSettingsHandler}
                    >
                      <IntlMessages id="save" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingIcon />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  getData: api.get(apiUrls.landings),
  saveData: api.patch(apiUrls.landings),
})(Landings);
