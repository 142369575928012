import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FloatLocales } from "components/special/contents";
import { useIntl } from "react-intl";
import ReactQuill from "react-quill";
import { adminAPI } from "redux/api";
import { useDispatch } from "react-redux";

const { api } = adminAPI.actions;

export const LocalesValues = (props) => {
  const { values = [], changeValues, isHtml, rows = 1, height = 100 } = props;
  const intl = useIntl();
  return (
    <div>
      {_.map(values, (elem, index) => {
        return (
          <div className="row mb-1" style={isHtml ? { height: height + 70 } : {}} key={index}>
            <div className="col-3">
              <FloatLocales
                _id={elem.localeId}
                showTitle={true}
                showCount={false}
                multi={false}
                successHandler={([localeId]) => {
                  Object.assign(values[index], { localeId });
                  changeValues(values);
                }}
              />
            </div>
            <div className="col">
              {isHtml ? (
                <ReactQuill
                  value={elem.value || ""}
                  style={{ height }}
                  onChange={(value) => {
                    Object.assign(values[index], { value });
                    changeValues(values);
                  }}
                />
              ) : (
                <textarea
                  type="text"
                  rows={rows}
                  className="form-control"
                  placeholder={intl.formatMessage({ id: "value" })}
                  value={elem.value || ""}
                  onChange={({ target }) => {
                    Object.assign(values[index], { value: target.value });
                    changeValues(values);
                  }}
                />
              )}
            </div>
            <div className="col-1">
              <LocalesValues.RemoveButton {...props} index={index} />
            </div>
            <div className="col-1">
              <AutoTranslate
                {...props}
                index={index}
                disabled={!(values[index]?.localeId && _.trim(values[index]?.value))}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

LocalesValues.AddButton = (props) => {
  const { values = [], changeValues } = props;
  const addValue = () => {
    changeValues(values.concat([{ locale: null, value: null }]));
  };
  return (
    <button className={`btn btn-success ${props.className}`} onClick={addValue}>
      <i className="fa fa-plus" />
    </button>
  );
};

LocalesValues.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

const doTranslate = api.post("/api/translate");

const AutoTranslate = (props) => {
  const { values = [], index, changeValues } = props;
  const [currentData, setCurrentData] = useState(null);
  useEffect(() => {
    setCurrentData(values[index]);
  }, [props]);
  const dispatch = useDispatch();

  const selectValueHandler = (localesId) => {
    const body = { ...values[index], localesId };
    dispatch(
      doTranslate(
        { body },
        {
          onSuccess(body) {
            changeValues([...values, ...body]);
          },
        }
      )
    );
    // changeValues([...values, ])
    // changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <FloatLocales
      _id={null}
      className="btn btn-sm btn-info"
      icon={"fa fa-language"}
      showTitle={false}
      showCount={true}
      multi={true}
      disabled={props.disabled}
      successHandler={selectValueHandler}
    />
  );
};

export default LocalesValues;
