import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "react-router-redux";
import { useParams } from "react-router-dom";
import { LoadingIcon, setOnChange } from "components/special";
import { adminAPI } from "redux/api";
import { apiUrls, emitsInfo } from "./init";

const { api } = adminAPI.actions;

const EmitItems = (props) => {
  const { emitId } = props;
  const [emitItems, setEmitItems] = useState([]);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (emitItems.length) {
      const data = [];
      emitItems.forEach((item) => {
        data.push(`${item.ident}`);
      });
      const element = document.createElement("a");
      const file = new Blob([data.join("\r\n")], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `emit_items_${emitId}.csv`;
      document.body.appendChild(element);
      element.click();
      element.remove();
    }
  }, [emitItems]);

  const startDownload = () => {
    props.getItems(
      { params: { emitId } },
      {
        onSuccess(body) {
          setEmitItems(body);
          setLoading(false);
        },
        onRequest() {
          setEmitItems([]);
          setLoading(true);
        },
      }
    );
  };

  return (
    <button className={`btn ${props.className}`} onClick={startDownload}>
      {loading ? <LoadingIcon /> : <i className="fa fa-file-excel" />}
    </button>
  );
};

export default connect(null, {
  getItems: api.get(apiUrls.emitItems),
})(EmitItems);
