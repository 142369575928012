/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { PageTitle } from "components/standart";
import { LocalesValues, Uploader, ImagePreview } from "components/standart";
import DatePicker from "react-datepicker";

import { FloatMarketplaces } from "components/special/contents";

import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { Alert } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import adminAPI from "redux/api/admin";
import { SwitchValue } from "components/special/forms";

import { apiUrls } from "../init";
const { api } = adminAPI.actions;

const FeedbackForm = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs, feedback, setFeedback } = props;
  const params = useParams();
  const [dataSaved, setDataSaved] = useState(false);

  const changeFeedback = setOnChange(feedback, setFeedback);
  breadCrumbs.push({
    title: <IntlMessages id="Www.Feedbacks.Form" />,
    url,
  });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return _.filter(result, _.size);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const filesId = reorder(feedback.filesId, result.source.index, result.destination.index);
    setFeedback({ ...feedback, filesId });
  };

  const successAlertRef = useRef(null);

  const saveHandler = () => {
    const onSuccess = (body) => {
      setFeedback(body);
      setDataSaved(true);
      successAlertRef.current.scrollIntoView();
    };

    const onRequest = () => {
      setDataSaved(false);
    };

    const onFailure = () => {
      setDataSaved(false);
    };

    props.saveFeedback({ params, body: feedback }, { onSuccess, onFailure, onRequest });
  };

  const uploadFilesHandler = (files) => {
    const { filesId = [] } = feedback;
    _.map(files, ({ _id }) => filesId.push(_id));
    setFeedback({ ...feedback, filesId });
  };

  return (
    <div>
      <PageTitle {...breadCrumbs} />

      <div className="row">
        <div className="col-12">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                <Alert
                  className="alert-success"
                  isOpen={dataSaved}
                  toggle={() => setDataSaved(false)}
                >
                  <IntlMessages id="saved" />
                </Alert>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Www.Feedbacks.value" />
                </label>
                <div className="col-8">
                  <input
                    name="value"
                    value={feedback.value || ""}
                    onChange={changeFeedback}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Marketplaces" />
                </label>
                <div className="col-8">
                  <FloatMarketplaces
                    _id={feedback.marketplaceId}
                    showTitle={true}
                    showCount={false}
                    multi={false}
                    successHandler={([marketplaceId]) => {
                      setFeedback({ ...feedback, marketplaceId });
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Www.Feedbacks.realDate" />
                </label>
                <div className="col-8">
                  <DatePicker
                    selected={feedback.realDate ? new Date(feedback.realDate) : ""}
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    onChange={(realDate) => setFeedback({ ...feedback, realDate })}
                    className="form-control -custom-datepicker"
                    calendarClassName="custom-calender-class"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id={`Www.Feedbacks.localesName`} />
                  <LocalesValues.AddButton
                    className="btn-sm float-right"
                    values={feedback.localesName}
                    changeValues={(localesName) => setFeedback({ ...feedback, localesName })}
                  />
                </label>
                <div className="col-8">
                  <LocalesValues
                    values={feedback.localesName}
                    // isHtml={true}
                    changeValues={(localesName) => setFeedback({ ...feedback, localesName })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id={`Www.Feedbacks.localesText`} />
                  <LocalesValues.AddButton
                    className="btn-sm float-right"
                    values={feedback.localesText}
                    changeValues={(localesText) => setFeedback({ ...feedback, localesText })}
                  />
                </label>
                <div className="col-8">
                  <LocalesValues
                    values={feedback.localesText}
                    isHtml={true}
                    changeValues={(localesText) => setFeedback({ ...feedback, localesText })}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Www.Feedbacks.images" />

                  <Uploader
                    className="btn-success btn-sm pull-right"
                    successHandler={uploadFilesHandler}
                    imagesOnly="true"
                    multiple={true}
                  />
                </label>
                <div className="col-8">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className="-d-flex"
                          //style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          {_.map(feedback.filesId, (_id, index) => {
                            return (
                              <Draggable key={_id} draggableId={_id} index={index}>
                                {(provided, snapshot) => (
                                  <span
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <ImagePreview
                                      file={{ _id }}
                                      deleteHandler={() => {
                                        const filesId = _.filter(
                                          feedback.filesId,
                                          (fileId) => fileId !== _id
                                        );
                                        setFeedback({ ...feedback, filesId });
                                      }}
                                    />
                                  </span>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Www.Feedbacks.indexPage" />
                </label>
                <div className="col-8 my-auto">
                  <SwitchValue
                    value={feedback.indexPage}
                    name="indexPage"
                    onChange={changeFeedback}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="tables.enabled" />
                </label>
                <div className="col-8 my-auto">
                  <SwitchValue value={feedback.enabled} onChange={changeFeedback} />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-4 col-auto">
                  <button className="form-control c-primary" type="button" onClick={saveHandler}>
                    <IntlMessages id="save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButton = (props) => {
  const { feedbackForm, feedback } = props;
  return (
    <Link to={feedbackForm(feedback)} className="btn btn-secondary btn-sm">
      <i className="fa fa-edit" />
    </Link>
  );
};

export default connect(null, {
  saveFeedback: api.patch(apiUrls.feedbackInfo),
})(FeedbackForm);
