/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import moment from "moment";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { FormButton } from "./Form";
import { useIntl } from "react-intl";
import { Finder, FilterButton, Pager, Sorter, TableLoading } from "components/standart";
import { history } from "redux/store";
import { limit } from "variables";
import { apiUrls } from "./init";
import { FloatCategories } from "components/special/items";

const { api } = adminAPI.actions;

const ContentsTable = (props) => {
  const { url } = useRouteMatch();
  const params = useParams();
  const intl = useIntl();
  const [itemsData, setItemsData] = useState({ total: 0 });
  const [query, setQuery] = useState({});

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search), ...props.query });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setItemsData(body);
    };
    const onRequest = () => {
      setItemsData({ total: -1 });
    };

    query.limit && props.getItems({ query: { ...query, ...params } }, { onSuccess, onRequest });
  }, [query]);

  return (
    <>
      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <FloatCategories
                className="btn-sm mr-1"
                _id={query.categoryId}
                showTitle={false}
                showCount={true}
                multi={true}
                successHandler={([...categoryId]) => {
                  setQuery({ ...query, categoryId });
                }}
              />
              <IntlMessages id="Items.category" />
            </th>
            <th>
              <Finder className="mr-1 d-inline" isActive={query.ident}>
                <Finder.input name="ident" {...{ query, setQuery }} />
              </Finder>
              <IntlMessages id="Items.ident" />
            </th>
            <th>
              <IntlMessages id="Items.activateDate" />
              <Sorter query={query} url={url} order="activateDate" />
            </th>
            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>
            <th>
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
            </th>
          </tr>
        </thead>
        {itemsData.total < 0 && <TableLoading />}
        <tbody>
          {_.map(itemsData.data, (item, key) => (
            <tr key={key} className={`${!item.enabled ? "text-strike" : ""}`}>
              <td>{item.category?.name}</td>
              <td>{item.ident}</td>
              <td>{item.activateDate ? moment(item.activateDate).format(dateTimeFormat) : null}</td>
              <td>{moment(item.createdAt).format(dateTimeFormat)}</td>
              <td>
                <FormButton {...{ item }} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={itemsData.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getItems: api.get(apiUrls.list),
})(ContentsTable);
