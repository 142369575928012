/** форма клиента
 */
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import PageTitle from "components/standart/PageTitle";

import Data from "./Data";
import { Alert } from "reactstrap";

import adminAPI from "redux/api/admin";

import { apiUrls, formLink } from "./init";
import LoginsTable from "./Logins/LoginsTable";

const { api } = adminAPI.actions;

export const ident = "Customers.Form";

const Form = (props) => {
  const { url } = useRouteMatch();
  const params = useParams();
  const { customer, setCustomer, breadCrumbs } = props;

  breadCrumbs.push({
    title: <IntlMessages id={ident} />,
    url,
  });

  const [dataSaved, setDataSaved] = useState(false);
  const saveDataHandler = (req, res) => {
    res.onSuccess = (body) => {
      setCustomer(body);
      setDataSaved(true);
    };
    props.saveData({ ...req, params }, res);
  };

  const successAlertRef = useRef();
  useEffect(() => {
    if (dataSaved) {
      successAlertRef.current.scrollIntoView();
    }
  }, [dataSaved]);

  return (
    <>
      <PageTitle {...breadCrumbs} />

      <div ref={successAlertRef}>
        <Alert className="alert-success" isOpen={dataSaved} toggle={() => setDataSaved(false)}>
          <IntlMessages id="saved" />
        </Alert>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">{<IntlMessages id="Customers.Info" />}</div>
            <div className="roe-card-body">
              <Data
                {...{ customer, setCustomer, saveDataHandler }}
                requestHandler={() => setDataSaved(false)}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">{<IntlMessages id="Logins" />}</div>
            <div className="roe-card-body">
              <LoginsTable />
            </div>
          </div>
        </div>
      </div>
    </> ///
  );
};

export const FormButton = (props) => {
  const { customer } = props;
  return (
    <Link to={formLink(customer)} className={props.className}>
      <i className="fa fa-edit" />
    </Link>
  );
};

export default connect(null, {
  saveData: api.patch(apiUrls.info),
})(Form);
