/** модальное окно для выбора категорий в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import adminAPI from "redux/api/admin";
import { onEnter, setOnChange } from "components/special";
import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatQuotesCategoriesModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Contents.DailyQuotesCategories" />}
      {...props}
      head={(searchCategories, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="Contents.name" />
            </th>
            <th>
              <IntlMessages id="Contents.isPaid" />
            </th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(category, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!category.enabled ? "text-strike" : ""}>
            {category.name}
          </td>
          <td>
            <IntlMessages id={`${category.isPaid ? "yes" : "no"}`} />
          </td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatCategories = (props) => {
  return <FloatQuotesCategoriesModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/contents/daily_quotes_categories"),
})(FloatCategories);
