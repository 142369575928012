import React, { useCallback, useMemo, useState } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import { Editor, Transforms, createEditor } from "slate";
import { withHistory } from "slate-history";

const SlateEditor = (props) => {
    const editor = useMemo(() => withReact(createEditor()), []);

    return (
        <Slate editor={editor} {...props}>
            <Editable />
        </Slate>
    );
};
export default SlateEditor;
