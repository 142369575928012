import _ from "lodash";
import React, { useState } from "react";
import { LoadingIcon } from "components/special";
import { connect } from "react-redux";
import adminAPI from "redux/api/admin";

const { api } = adminAPI.actions;

const GiphyPreview = (props) => {
  const { tag } = props;

  const [imageData, setImageData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const refreshHandler = (rating) => () => {
    const query = { tag, rating };

    const onSuccess = (result) => {
      setIsLoading(false);
      setImageData(result);
    };
    const onRequest = () => {
      setIsLoading(true);
      setImageData(null);
    };

    props.getImageData({ query }, { onSuccess, onRequest });
  };

  const ratings = ["r", "pg13", "pg"];

  return (
    <div>
      <div className="mb-2">
        {ratings.map((rating, index) => {
          return (
            <button key={index} className="btn btn-secondary mr-2" onClick={refreshHandler(rating)}>
              {rating.toUpperCase()}
            </button>
          );
        })}
      </div>
      <div style={{ height: "200px" }}>
        {isLoading && <LoadingIcon />}
        {imageData
          ? _.size(imageData.data) && <img src={imageData.data.images.downsized.url} width="100%" />
          : null}
      </div>
    </div>
  );
};

export default connect(null, { getImageData: api.get("/api/giphy") })(GiphyPreview);
