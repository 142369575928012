import _ from "lodash";
import { RSAA } from "redux-api-middleware"; // RSAA = '@@redux-api-middleware/RSAA'
import { accessToken, generateUrl, autoDetect } from "./index";

export const SUCCESS = "BACKEND_API_SUCCESS";
export const FAILURE = "BACKEND_API_FAILURE";
export const REQUEST = "BACKEND_API_REQUEST";

const { REACT_APP_VERSION } = process.env;
export default class {
  constructor(initState = {}) {
    this.states = {};
    this.actions = {};
    this.middlewares = {};
    this.initState = initState;
  }

  get(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "GET", { success, failure, request }, this);
  }

  post(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "POST", { success, failure, request }, this);
  }

  patch(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "PATCH", { success, failure, request }, this);
  }

  put(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "PUT", { success, failure, request }, this);
  }

  delete(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "DELETE", { success, failure, request }, this);
  }

  auto(endpoint, { success, failure, request } = {}, options = {}) {
    return createApi(endpoint, "AUTO", { success, failure, request }, this, options);
  }

  upload(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "POST", { success, failure, request }, this, {
      upload: true,
    });
  }

  // создать редюсер, который в контексте своего объекта будет вызывать
  reducer() {
    const { states, initState } = this;
    return (state = initState, action) => {
      if (_.isFunction(_.get(states, action.type))) {
        return states[action.type](state, action.payload);
      }
      return state;
    };
  }

  middleware() {
    const { states, actions, middlewares } = this;
    return (store) => (next) => (action) => {
      const { IdempotenceKey } = action.meta || {};
      if (_.isFunction(_.get(middlewares, action.type + IdempotenceKey))) {
        // тут может быть баг: если повторно вызван этот же запрос, пока другой не завершился
        // с указанием другой функции миддливара
        // вилка для того, чтобы вернуть в случае ошибки оригинальный response со стороны сервера
        const payload = _.get(action, "payload.response") || _.get(action, "payload");
        middlewares[action.type + IdempotenceKey](payload);
        // удалим middleware после его запуска
        delete middlewares[action.type + IdempotenceKey];
      }
      return next(action);
    };
  }
}

export const defaults = {
  success(state) {
    return { ...state };
  },
  request(state) {
    return { ...state, _last_request: new Date() };
  },
  failure(state, result) {
    return {
      ...state,
      _last_error: _.get(result, "response.message") || _.get(result, "statusText"),
    };
  },
};

// генератор вызовов
export function createApi(url, method = "GET", props, reducer, options = {}) {
  const { success, failure, request } = _.omit(props);

  const { postfix = Math.random() } = options;
  let ON_SUCCESS = `${method} ${url}/${SUCCESS}_${postfix}`;
  reducer.states[ON_SUCCESS] = success || defaults.success;

  let ON_FAILURE = `${method} ${url}/${FAILURE}_${postfix}`;
  reducer.states[ON_FAILURE] = failure || defaults.failure;

  let ON_REQUEST = `${method} ${url}/${REQUEST}_${postfix}`;
  reducer.states[ON_REQUEST] = request || defaults.request;

  const types = [{ type: ON_REQUEST }, { type: ON_SUCCESS }, { type: ON_FAILURE }];

  const headers = { "Content-Type": "application/json" };

  const isUpload = options.upload;
  // если в опциях указано, что используется upload файлов
  if (isUpload) {
    delete headers["Content-Type"]; // то удалим Content-type, чтобы он был установлен автоматически
  }

  return (req = {}, res = {}) => {
    const { body, query, params } = req;
    const token = localStorage.getItem(accessToken);
    if (token) {
      _.merge(headers, { Authorization: token }, req.headers);
    }

    const IdempotenceKey = Math.random();
    headers["Idempotence-Key"] = IdempotenceKey;
    headers["Build-Version"] = REACT_APP_VERSION;
    headers["Origin-Url"] = url;
    const route = autoDetect(url, method);
    const endpoint = generateUrl(route.url, { params, query });

    // вот тут следует
    reducer.middlewares[ON_SUCCESS + IdempotenceKey] = res.onSuccess;
    reducer.middlewares[ON_FAILURE + IdempotenceKey] = res.onFailure;
    reducer.middlewares[ON_REQUEST + IdempotenceKey] = res.onRequest;
    // console.log (new Date, 'do rsaa', {endpoint, method, headers, ON_SUCCESS, 'mw': reducer.middlewares[ON_SUCCESS]});

    /*
        if (isUpload) {
            reducer.middlewares[ON_PROGRESS] = res.onProgress;
        }
        // */

    return {
      [RSAA]: {
        endpoint,
        headers,
        method: route.method,
        body: isUpload ? body : JSON.stringify(body),
        types: _.map(types, (type) => ({ ...type, meta: { IdempotenceKey } })),
        // meta: { headers },
        credentials: "include",
      },
    };
  };
}
