export const path = "/payments";

export const apiUrls = {
  payments: "/api/payments",
  paymentsInfo: "/api/payments/id_{paymentId}",
  receipts: "/api/payments/receipts",
  receiptsInfo: "/api/payments/receipts/id_{receiptId}",
  receiptsServices: "/api/payments/receipts/services",
  receiptsServicesInfo: "/api/payments/receipts/services/service_{serviceId}",
  paymentsServices: "/api/payments/services",
  paymentsServicesInfo: "/api/payments/services/service_{serviceId}",
  paymentsCurrencies: "/api/payments/currencies",
  paymentsCurrenciesInfo: "/api/payments/currencies/currency_{currencyId}",
};
