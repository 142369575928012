import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
// import SalePredictionWrapper from "./saleprediction.style";
import NumberFormat from "react-number-format";
import IntlMessages from "util/intlMessages";
import { useIntl } from "react-intl";
import customTooltip from "components/common/chartTooltip";
import { colors } from "./init";

export const options = {
  tooltips: {
    enabled: false,
    custom: customTooltip,
  },
  cutoutPercentage: 75,
  animationEasing: "easeOutBounce",
  animateRotate: true,
  animateScale: false,
  responsive: true,
  maintainAspectRatio: false,
  showScale: true,
  legend: {
    display: false,
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
};

const DataPieChart = (props) => {
  const [dataset, setDataset] = useState({});
  const [labels, setLabels] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [resultValue, setResultValue] = useState(0);
  const intl = useIntl();
  const { shift = 0 } = props;

  useEffect(() => {
    const dataset = {
      data: [],
      backgroundColor: [],
      borderColor: [],
      hoverBackgroundColor: [],
      hoverBorderColor: [],
    };
    const labels = [];
    let total = 0;
    if (props.resultValue) {
      setResultValue(props.resultValue(props.data));
    }
    _.map(props.data, (stat, index) => {
      const value = _.get(stat, props.value);
      const label = _.get(stat, props.label);
      total += value;
      dataset.data.push(value);
      const color = colors[(index + shift) % colors.length];
      dataset.backgroundColor.push(color);
      dataset.borderColor.push(color);
      dataset.hoverBackgroundColor.push("#a7afb7");
      dataset.hoverBorderColor.push("#a7afb7");
      labels.push(
        props.labelPrefix ? intl.formatMessage({ id: `${props.labelPrefix}.${label}` }) : label
      );
    });
    setTotalValue(total);
    setDataset(dataset);
    setLabels(labels);
  }, [props]);
  return (
    <div className="card roe-shadow-2 fill-height">
      <div className="card-body">
        <div className="card-title h4">
          {typeof props.title === "string" ? <IntlMessages id={props.title} /> : <>{props.title}</>}
          {resultValue ? <span className="float-right">{resultValue}</span> : null}
        </div>
        <div className="row">
          <div className="col-md-6 -aligner-wrapper mt-15">
            <Doughnut data={{ datasets: [dataset], labels }} height={160} options={options} />
            <div
              className="wrapper d-flex flex-column justify-content-center absolute absolute-center middle-block"
              style={{
                position: "absolute",
                top: "50%",
                right: "0",
                left: "0",
                margin: "auto",
                transform: "translateY(-50%)",
              }}
            >
              <div className="d-block text-center">
                <h4 className="fs-20">
                  <NumberFormat displayType="text" thousandSeparator={" "} value={totalValue} />
                </h4>
                <small className="text-muted">
                  <IntlMessages id="total" />
                </small>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-2 -legend-wrapper">
            {props.legend ? (
              <div className="font-weight-bold py-2">
                {intl.formatMessage({ id: props.legend })}
              </div>
            ) : null}
            <div className="row">
              {_.map(dataset.data, (stat, index) => {
                const value = dataset.data[index];
                const label = labels[index];
                const color = _.get(dataset.backgroundColor, index);
                const percent = totalValue > 0 ? _.round((value / totalValue) * 100, 2) : 0;
                return (
                  <div
                    key={index}
                    className="d-flex flex-column justify-content-center mb-3 col-6 col-md-12"
                  >
                    <div className="d-flex align-items-center">
                      <i className="fas fa-circle  mt-1 mr-2" style={{ color }} />
                      <h4 className="mb-0 text-dark fs-20">
                        <NumberFormat displayType="text" thousandSeparator={" "} value={value} />

                        {props.showPercent && (
                          <span className="ml-2 text-muted">({percent} %)</span>
                        )}
                      </h4>
                    </div>
                    <small className="text-muted ml-3">{label}</small>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataPieChart;
