/** модальное окно для выбора категорий в требуемых случаях */
import _ from "lodash";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import adminAPI from "redux/api/admin";
import { onEnter, setOnChange } from "components/special";
import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatCategoriesModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Items.Categories" />}
      {...props}
      head={(searchCategories, searchQuery, setSearchQuery) => {
        return (
          <>
            <th colSpan="2">
              <IntlMessages id="Items.Categories.name" />
            </th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(category, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!category.enabled ? "text-strike" : ""}>
            {category.name}
          </td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatCategories = (props) => {
  return <FloatCategoriesModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/items/categories"),
})(FloatCategories);
