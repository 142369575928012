/** модальное окно для выбора градиентов в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import adminAPI from "redux/api/admin";
import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatAspectsValuesModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Contents.AspectsValues" />}
      {...props}
      head={(searchGradients, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="Contents.name" />
            </th>
            <th>
              <IntlMessages id="Contents.astroAspect" />
            </th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(value, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!value.enabled ? "text-strike" : ""}>
            {value.name}
          </td>
          <td>{value.astroAspect}</td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatAspectsValues = (props) => {
  return <FloatAspectsValuesModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/contents/aspects_values"),
})(FloatAspectsValues);
