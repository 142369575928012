import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch, Link, useParams, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import PageTitle from "components/standart/PageTitle";
import ReactQuill from "react-quill";

import Form from "./Form";

import adminAPI from "redux/api/admin";
import { apiUrls } from "../init";
const { api } = adminAPI.actions;

const ContentInfo = (props) => {
  const { url, path } = useRouteMatch();

  const params = useParams();

  const [content, setContent] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const { breadCrumbs, contentForm } = props;

  if (content) {
    breadCrumbs.push({
      title: content.title,
      url,
      actions: [
        <Link to={contentForm(content)}>
          <i className="fa fa-edit" />
        </Link>,
      ],
    });
  }

  useEffect(() => {
    const onSuccess = (body) => {
      setContent(body);
    };
    const onFailure = ({ status }) => {
      setNotFound(404 === status);
    };

    props.getContent({ params }, { onSuccess, onFailure });
  }, []);

  return (
    <>
      {notFound && <Error400 />}

      {content && (
        <Switch>
          <Route exact path={path} render={() => <Redirect to={`${path}/form`} />} />

          <Route exact path={`${path}`}>
            <div>
              <PageTitle {...breadCrumbs} />

              <div>{content.data}</div>
            </div>
          </Route>

          <Route exact path={`${path}/form`}>
            <Form {...{ breadCrumbs, content, setContent }} />
          </Route>

          <Route component={Error400} />
        </Switch>
      )}
    </>
  );
};

export default connect(null, {
  getContent: api.get(apiUrls.aspectsQuotesInfo),
})(ContentInfo);
