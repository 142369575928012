/** форма пользователя
 * в общем виде является высокоприоритетной формой, которая позволяет
 * 1) изменить логин и пароль
 * 2) обновить права доступа
 * 3) добавить телефон и емейл (возможно с их проверкой на принадлежность одноразовым кодом)
 * 4) что-то еще
 *
 * может использовать общие компоненты из пользовательских настроек (загрузить фото и сменить пароль)
 *
 */
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import PageTitle from "components/standart/PageTitle";

import UserData from "./UserData";
import { Alert } from "reactstrap";

import adminAPI from "redux/api/admin";
import { apiUrls, formLink } from "./init";
import LoginsTable from "./Logins/LoginsTable";

const { api } = adminAPI.actions;

export const ident = "Users.User.Save";

const UserForm = (props) => {
  const { url } = useRouteMatch();
  const params = useParams();
  const { user, setUser, breadCrumbs } = props;

  const changePassword = (req = {}, res = {}) => {
    props.changePassword({ ...req, params }, res);
  };

  breadCrumbs.push({
    title: <IntlMessages id={ident} />,
    url,
  });

  const changePhoto = (req = {}, res = {}) => {
    res.onSuccess = (body) => {
      setUser(body);
    };
    props.changePhoto({ ...req, params }, res);
  };

  const [userSaved, setUserSaved] = useState(false);
  const saveUserHandler = (req, res) => {
    res.onSuccess = (body) => {
      setUser(body);
      setUserSaved(true);
    };
    props.saveUser({ ...req, params }, res);
  };

  const successAlertRef = useRef();
  useEffect(() => {
    if (userSaved) {
      successAlertRef.current.scrollIntoView();
    }
  }, [userSaved]);

  return (
    <>
      <PageTitle {...breadCrumbs} />

      <div ref={successAlertRef}>
        <Alert className="alert-success" isOpen={userSaved} toggle={() => setUserSaved(false)}>
          <IntlMessages id="saved" />
        </Alert>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">{<IntlMessages id="Users.info" />}</div>
            <div className="roe-card-body">
              <UserData
                {...{ user, setUser }}
                requestHandler={() => setUserSaved(false)}
                saveUserHandler={saveUserHandler}
              />
            </div>
            {/* 
            <div className="roe-card-body">
              <div className="h6">
                <IntlMessages id="settings.photo" />
              </div>
              <PhotoUploader userSettings={user.settings} changePhotoHandler={changePhoto} />
            </div>
            */}
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">{<IntlMessages id="Logins" />}</div>
            <div className="roe-card-body">
              <LoginsTable />
            </div>
            {/* 
              <ChangePassword changePasswordHandle={changePassword} />
             */}
          </div>
        </div>
        {/*
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">{<IntlMessages id="settings.changePassword" />}</div>
            <div className="roe-card-body">
              <ChangePassword changePasswordHandle={changePassword} />
            </div>
          </div>
          <br />
          <div className="roe-card-style">
            <div className="roe-card-header">
              <IntlMessages id="Users.Form.changePhone" />
            </div>
            <div className="roe-card-body">
              <UserPhone {...{ user, setUser, saveUserHandler }} />
            </div>
          </div>
        </div>
        */}
      </div>
    </> ///
  );
};

export const FormButton = (props) => {
  const { user } = props;
  return (
    <Link to={formLink(user)} className={props.className}>
      <i className="fa fa-edit" />
    </Link>
  );
};

export default connect(null, {
  saveUser: api.patch(apiUrls.info),
  // changePassword: api.auto("Users.User.ChangePassword"),
  // changePhoto: api.auto("Users.User.ChangePhoto"),
})(UserForm);
