import _ from "lodash";
import React, { useState, useEffect } from "react";
import IntlMessages from "util/intlMessages";

const SettingsFloatModal = (props) => {
  const { attrName, data, setData, modal } = props;
  const [currentValue, setCurrentValue] = useState(data[attrName]);

  useEffect(() => {
    console.log({ currentValue, data, setData });
    setData({ ...data, [attrName]: currentValue });
  }, [currentValue]);

  return (
    <>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id={`Www.Settings.${attrName}`} />
        </label>
        <div className="col-8">
          <props.modal
            _id={currentValue}
            multi={false}
            showTitle={true}
            showCount={false}
            successHandler={([value]) => {
              setCurrentValue(value);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SettingsFloatModal;
