import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";

import { SettingsFloatModal, SocialBlockAction } from "components/special/forms";
import { FloatAspectsCategories, FloatTags } from "components/special/contents";

import { connect } from "react-redux";
import { adminAPI } from "redux/api";

import SettingsForm from "./Form";

const SettingsNotifications = (props) => {
  // аттрибуты, которые необходимо отобразить
  const attrs = ["notificationTime"];

  // аттрибуты, которые необходимо отобразить
  const localesAttrs = [
    { attr: "notificationTextUp" },
    { attr: "notificationTextDown" },
    { attr: "notificationButtonText" },
    { attr: "notificationMessageText", multiLine: true },
  ];
  const booleanAttrs = [];
  const htmlAttrs = [];
  const filesAttrs = [];

  const componentAttrs = [
    {
      name: "notificationCategoryId",
      component: (props) => <SettingsFloatModal {...props} modal={FloatAspectsCategories} />,
    },
  ];

  return (
    <SettingsForm
      {...{ componentAttrs, filesAttrs, htmlAttrs, booleanAttrs, localesAttrs, attrs }}
    />
  );
};

export default connect(null, {})(SettingsNotifications);
