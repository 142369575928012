/** страница типа транспорта
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useRouteMatch, Route, Switch } from "react-router-dom";
import { Error400 } from "views/pages";
import PageTitle from "components/standart/PageTitle";
import CustomerData from "./Customer";
import Form, { FormButton } from "./Form";
import { apiUrls } from "./init";

import adminAPI from "redux/api/admin";

const { api } = adminAPI.actions;

const Info = (props) => {
  const { url, path } = useRouteMatch();
  const params = useParams();

  const { breadCrumbs } = props;
  const [customer, setCustomer] = useState(null);

  const [notFound, setNotFound] = useState(false);

  if (customer) {
    breadCrumbs.push({
      title: [customer.name].filter(_.size).join(" / "),
      url,
      actions: [<FormButton {...{ customer }} />],
    });
  }

  useEffect(() => {
    const onSuccess = (body) => {
      setCustomer(body);
    };
    const onFailure = ({ status }) => {
      setNotFound(404 === status);
    };

    props.getData({ params }, { onSuccess, onFailure });
  }, []);

  return (
    <>
      {notFound && <Error400 />}

      {customer && (
        <Switch>
          <Route exact path={`${path}/form`}>
            <Form {...{ customer, setCustomer, breadCrumbs }} />
          </Route>
          <Route path={path}>
            {/* <Redirect to={formLink(customer)} /> */}

            <PageTitle {...breadCrumbs} />

            <CustomerData {...{ customer }} />
          </Route>

          <Route path="*" component={Error400} />
        </Switch>
      )}
    </>
  );
};

export default connect(null, {
  getData: api.get(apiUrls.info),
})(Info);
