export const path = "/items";

export const apiUrls = {
  list: "/api/items",
  info: "/api/items/item_{item_id}",
};

export const itemsInfo = (item) => {
  return `${path}/item_${item._id}`;
};

export const itemsForm = (item) => {
  return `${itemsInfo(item)}/form`;
};
