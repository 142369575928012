import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import SettingsForm from "./Form";

const SettingsCommon = (props) => {
  // аттрибуты, которые необходимо отобразить
  const attrs = [
    "phone",
    "email",
    "whatsapp",
    "instagram",
    "facebook",
    "vk",
    "versionNumber",
    "telegram",
    "startBotMessage",
    "supportBotMessage",
    "telegramAppUrl",
    "telegramChannelUrl",
    "telegramCommunityUrl",
    // "freeFriends",
  ];

  // аттрибуты, которые необходимо отобразить
  const localesAttrs = [
    { attr: "settingsIntroTitle" },
    { attr: "settingsIntroText", multiLine: true },
    { attr: "shareButtonText" },
    { attr: "shareScreenButton" },
    { attr: "shareScreenTitle" },
    { attr: "introVideo" },
  ];
  const booleanAttrs = ["allowSelectCountry"];
  const htmlAttrs = [
    "helpText",
    "stopText",
    // "shareScreenText",
    "softToys",
    "digitalToys",
  ];
  const filesAttrs = ["defaultImageId", "helpImageId"];

  const componentAttrs = [
    // { name: "adContacts", component: ContactsData },
    // { name: "inviteAction", component: SocialBlockAction },
    // { name: "giftAction", component: SocialBlockAction },
  ];

  return (
    <SettingsForm
      {...{ componentAttrs, filesAttrs, htmlAttrs, booleanAttrs, localesAttrs, attrs }}
    />
  );
};

export default connect(null, {})(SettingsCommon);
