import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { UncontrolledTooltip } from "reactstrap";

import adminAPI, { adminSockets } from "redux/api/admin";

const { api } = adminAPI.actions;

const NavStats = (props) => {
  const {
    stats,
    name,
    listname,
    statsQuery = "withStatusStats",
    statsKey = "status",
  } = props;
  const intl = useIntl();
  const ident = name || listname;
  const apiStore = useSelector((state) => state.api.api);
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date());

  useEffect(() => {
    try {
      const onmessage = function (msg) {
        setLastUpdate(msg.data);
      };
      adminSockets.auto(ident, {}, { onmessage });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const statsRequest = api.auto(ident);
    const req = {
      query: { [statsQuery]: true, limit: 1 },
    };
    const res = {
      onSuccess({ body }) {
        const data = _.keyBy(body.stats, "_id");
        const result = [];
        _.map(
          stats,
          (key) => _.get(data[`${key}`], "total") && result.push(data[key])
        );
        setResult(result);
      },
    };
    dispatch(statsRequest(req, res));
  }, [lastUpdate]);

  return (
    <div
      style={{
        position: "absolute",
        top: "-0.7em",
        right: "-0.3em",
        zIndex: 999,
      }}
    >
      {result.map(({ _id, total }, index) => {
        const style_id = `${ident}.${statsKey}.${_id}.style`;
        const style = intl.formatMessage({
          id: style_id,
          defaultMessage: style_id,
        });
        const title_id = `${ident}.${statsKey}.${_id}`;
        const title = intl.formatMessage({
          id: title_id,
          defaultMessage: title_id,
        });
        const id = _.camelCase(`indicator_${ident}_${_id}`);
        return (
          <Fragment key={index}>
            <span id={id} className={`badge p-1 ml-1 badge-${style}`}>
              {total}
            </span>
            <UncontrolledTooltip placement="top" autohide={false} target={id}>
              {title}
            </UncontrolledTooltip>
          </Fragment>
        );
      })}
    </div>
  );
};

export default NavStats;
