import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import adminAPI from "redux/api/admin";
import FormData from "./Data";
import { apiUrls, formLink } from "./init";

const { api } = adminAPI.actions;

const Add = (props) => {
  const dispatch = useDispatch();

  const [customer, setCustomer] = useState({});

  const saveDataHandler = (req, res) => {
    res.onSuccess = (body) => {
      dispatch(push(formLink(body)));
    };
    props.addCustomer(req, res);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  useEffect(() => {
    setCustomer({});
  }, [openModal]);

  return (
    <>
      <i className="fa fa-user-plus" onClick={toggleModal} />
      {openModal && (
        <Modal isOpen={openModal} centered size="sm" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={"Customers.Add"} />
          </ModalHeader>
          <ModalBody>
            <FormData {...{ customer, setCustomer, saveDataHandler }} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addCustomer: api.post(apiUrls.list),
})(Add);
