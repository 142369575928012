/** управление партнерами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import moment from "moment";
import { useIntl } from "react-intl";
import { useParams, Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { history } from "redux/store";
import { FormButton } from "./Form";
import { Finder, Pager, Sorter, TableLoading } from "components/standart";
import { limit } from "variables";

import { apiUrls, infoLink, formLink } from "./init";
const { api } = adminAPI.actions;

export const ident = "Users";

const DataTable = (props) => {
  const { url, path } = useRouteMatch();
  const params = useParams();
  const intl = useIntl();

  const [customers, setCustomers] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setCustomers(body);
    };
    const onRequest = () => {
      setCustomers({ total: -1 });
    };

    query.limit &&
      props.getData(
        { query: { order: "-createdAt", ...query, ...params } },
        { onSuccess, onRequest }
      );
  }, [query]);

  return (
    <Table responsive style={{ position: "relative" }}>
      <thead>
        <tr>
          <th>
            <IntlMessages id="Customers.ident" />
          </th>
          <th>
            <IntlMessages id="Customers.name" />
          </th>
          <th>
            <IntlMessages id="Customers.astroSign" />
          </th>
          <th>
            <IntlMessages id="Customers.email" />
          </th>
          <th>
            <IntlMessages id="Customers.itemsCount" />
          </th>

          <th>
            <IntlMessages id="tables.created" />
            <Sorter query={query} url={url} order="createdAt" />
          </th>

          <th>
            <Finder.search {...{ query, url }} />
          </th>
        </tr>
      </thead>
      {customers.total < 0 && <TableLoading />}
      <tbody>
        {_.map(customers.data, (customer, key) => {
          return (
            <tr key={key} className={!customer.enabled ? "text-strike" : ""}>
              <td>
                <Link to={infoLink(customer)}>{customer.ident || customer._id}</Link>
              </td>
              <td>{customer.name}</td>
              <td>
                {customer.astroSign ? (
                  <IntlMessages id={`AstroSigns.${customer.astroSign}`} />
                ) : null}
              </td>
              <td>{customer.email?.value}</td>
              <td>{customer.itemsCount}</td>
              <td>{moment(customer.createdAt).format(dateTimeFormat)}</td>

              <td>
                <FormButton {...{ formLink, customer }} className="btn btn-sm btn-secondary" />
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="99">
            <Pager query={query} url={url} total={customers.total} />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default connect(null, {
  getData: api.get(apiUrls.list),
})(DataTable);
