import _ from "lodash";
import React, { useCallback, useState } from "react";
import { LoadingIcon } from "components/special";
import { connect } from "react-redux";
import adminAPI from "redux/api/admin";

const { api } = adminAPI.actions;

const MemePreview = (props) => {
  const { data } = props;

  const [imageData, setImageData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const clickHandler = useCallback(() => {
    const { tagId, localesTextDown, localesTextUp } = data;

    const [{ value: textUp = "" }] = localesTextUp;
    const [{ value: textDown = "" }] = localesTextDown;

    const body = { tagId, textUp, textDown };

    const onSuccess = (result) => {
      setIsLoading(false);
      setImageData(result);
    };
    const onRequest = () => {
      setIsLoading(true);
      setImageData(null);
    };

    props.getImageData({ body }, { onSuccess, onRequest });
  }, [data]);

  return (
    <div>
      <div className="mb-2">
        <button className="btn btn-secondary" onClick={clickHandler}>
          {"meme"}
        </button>
      </div>
      <div style={{ height: "200px" }}>
        {isLoading && <LoadingIcon />}
        {imageData ? <img src={imageData.url} width="100%" /> : null}
      </div>
    </div>
  );
};

export default connect(null, { getImageData: api.post("/api/meme") })(MemePreview);
