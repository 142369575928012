import _ from "lodash";
import React, { useState, Fragment } from "react";
import { connect } from "react-redux";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import { Nav, NavItem } from "reactstrap";
import IntlMessages from "util/intlMessages";
import FeedbackTable from "./Table";
import { PageTitle } from "components/standart";
import AddFeedback from "./Add";
import FeedbackInfo from "./Info";

const Feedbacks = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs } = props;

  const feedbackInfo = (feedback) => {
    return `${url}/feedback_${feedback._id}`;
  };

  const feedbackForm = (feedback) => {
    return `${feedbackInfo(feedback)}/form`;
  };

  breadCrumbs.push({
    title: <IntlMessages id="Www.Feedbacks" />,
    url,
    actions: [<AddFeedback {...{ feedbackForm }} />],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />
        <FeedbackTable {...{ feedbackInfo, feedbackForm }} />
      </Route>

      <Route path={`${path}/feedback_:feedbackId`}>
        <FeedbackInfo {...{ breadCrumbs, feedbackInfo, feedbackForm }} />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(Feedbacks);
