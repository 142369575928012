/** модальное окно для выбора стран в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import adminAPI from "redux/api/admin";
import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatMemesModal = (props) => {
  return (
    <FloatModal
      limit={100}
      header={<IntlMessages id="Tags" />}
      {...props}
      head={(searchTags, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>#</th>
            <th>
              <IntlMessages id="Tags.name" />
            </th>
            <th style={{ width: "30px" }}>
              {/* <AddContent successHandler={() => setSearchQuery({})} /> */}
            </th>
          </>
        );
      }}
      row={(meme, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!meme.enabled ? "text-strike" : ""}>
            {meme.code}
          </td>
          <td onClick={clickHandler} className={!meme.enabled ? "text-strike" : ""}>
            {meme.name}
          </td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatMemes = (props) => {
  return <FloatMemesModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/contents/memes"),
})(FloatMemes);
