import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import SettingsForm from "./Form";

const SettingsShop = (props) => {
  // аттрибуты, которые необходимо отобразить
  const attrs = ["shopMerchUrl"];

  // аттрибуты, которые необходимо отобразить
  const localesAttrs = [
    { attr: "shopScreenTitle" },
    { attr: "shopScreenText", multiLine: true },
    { attr: "shopMerchTitle" },
    { attr: "shopMerchText", multiLine: true },
    { attr: "shopMerchButton" },
  ];
  const booleanAttrs = ["allowMerchShop"];
  const htmlAttrs = [];
  const filesAttrs = ["merchImageId"];

  const componentAttrs = [
    // { name: "adContacts", component: ContactsData },
    // { name: "inviteAction", component: SocialBlockAction },
    // { name: "giftAction", component: SocialBlockAction },
  ];

  return (
    <SettingsForm
      {...{ componentAttrs, filesAttrs, htmlAttrs, booleanAttrs, localesAttrs, attrs }}
    />
  );
};

export default connect(null, {})(SettingsShop);
