import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";

import { SocialBlockAction } from "components/special/forms";
import { connect } from "react-redux";
import { adminAPI } from "redux/api";

import SettingsForm from "./Form";

const SettingsInvites = (props) => {
  // аттрибуты, которые необходимо отобразить
  const attrs = [];

  // аттрибуты, которые необходимо отобразить
  const localesAttrs = [{ attr: "noFriendsText", multiLine: true }];
  const booleanAttrs = [];
  const htmlAttrs = [];
  const filesAttrs = ["noFriendsImageId"];

  const componentAttrs = [
    // { name: "adContacts", component: ContactsData },
    { name: "inviteAction", component: SocialBlockAction },
  ];

  return (
    <SettingsForm
      {...{ componentAttrs, filesAttrs, htmlAttrs, booleanAttrs, localesAttrs, attrs }}
    />
  );
};

export default connect(null, {})(SettingsInvites);
