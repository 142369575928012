import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { FloatCurrencies } from "components/special/payments";
import { FloatTypes } from "components/special/items";

export const CurrenciesValues = (props) => {
  const { values = [], changeValues } = props;
  const intl = useIntl();
  return (
    <div>
      {_.map(values, (elem, index) => {
        return (
          <div className="row mb-1" key={index}>
            <div className="col-3">
              <FloatCurrencies
                _id={elem.currencyId}
                showTitle={true}
                showCount={false}
                multi={false}
                successHandler={([currencyId]) => {
                  Object.assign(values[index], { currencyId });
                  changeValues(values);
                }}
              />
            </div>
            <div className="col-3">
              <FloatTypes
                _id={elem.itemTypeId}
                showTitle={true}
                showCount={false}
                multi={false}
                successHandler={([itemTypeId]) => {
                  Object.assign(values[index], { itemTypeId });
                  changeValues(values);
                }}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder={intl.formatMessage({ id: "value" })}
                value={elem.value || ""}
                onChange={({ target }) => {
                  Object.assign(values[index], { value: +target.value });
                  changeValues(values);
                }}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder={intl.formatMessage({ id: "oldValue" })}
                value={elem.oldValue || ""}
                onChange={({ target }) => {
                  Object.assign(values[index], { oldValue: +target.value });
                  changeValues(values);
                }}
              />
            </div>
            <div className="col-1">
              <CurrenciesValues.RemoveButton {...props} index={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

CurrenciesValues.AddButton = (props) => {
  const { values = [], changeValues } = props;
  const addValue = () => {
    changeValues(values.concat([{ value: null }]));
  };
  return (
    <button className={`btn btn-success ${props.className}`} onClick={addValue}>
      <i className="fa fa-plus" />
    </button>
  );
};

CurrenciesValues.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

export default CurrenciesValues;
