import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import NumberFormat from "react-number-format";
// import SalePredictionWrapper from "./saleprediction.style";
import IntlMessages from "util/intlMessages";
import { useIntl } from "react-intl";
import customTooltip from "components/common/chartTooltip";
import { colors } from "./init";

export const options = {
  tooltips: {
    enabled: false,
    custom: customTooltip,
  },
  cutoutPercentage: 75,
  animationEasing: "easeOutBounce",
  animateRotate: true,
  animateScale: false,
  responsive: true,
  maintainAspectRatio: false,
  showScale: true,
  legend: {
    display: false,
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
};

const StatusesPieChart = (props) => {
  const [dataset, setDataset] = useState({});
  const [labels, setLabels] = useState([]);
  const [columnsStatuses, setColumnsStatuses] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const intl = useIntl();
  const { shift = 0 } = props;

  useEffect(() => {
    const dataset = {
      data: [],
      backgroundColor: [],
      borderColor: [],
      hoverBackgroundColor: [],
      hoverBorderColor: [],
    };
    const labels = [];
    let total = 0;
    _.map(props.statuses, (status, index) => {
      const value =
        _.get(props.stats[status], "total") || _.toSafeInteger(props.stats[status]) || 0;
      total += value;
      dataset.data.push(value);
      const color = colors[(index + shift) % colors.length];
      dataset.backgroundColor.push(color);
      dataset.borderColor.push(color);
      dataset.hoverBackgroundColor.push("#a7afb7");
      dataset.hoverBorderColor.push("#a7afb7");

      labels.push(intl.formatMessage({ id: `${props.label}.${status}` }));
    });
    setTotalValue(total);
    setDataset(dataset);
    setLabels(labels);

    const colsCount = props.cols || 1; // количество колонок
    const columnsSplit = []; // разбитие по колонкам
    const columnsSize = _.ceil(_.size(props.statuses) / colsCount); // максимальный размер колонки
    let columnsSplitIndex = 0; // текущий индекс
    _.map(_.range(0, props.cols || 1), () => {
      let columnData = [];
      _.map(_.range(0, columnsSize), () => {
        if (props.statuses[columnsSplitIndex]) {
          columnData.push({ status: props.statuses[columnsSplitIndex], index: columnsSplitIndex });
          columnsSplitIndex++;
        }
      });
      columnsSplit.push(columnData);
      console.log("columns split", columnsSplit);
    });
    setColumnsStatuses(columnsSplit);
  }, [props]);
  return (
    <div className="card roe-shadow-2 fill-height">
      <div className="card-body">
        <div className="card-title h4">
          <IntlMessages id={props.title} />
        </div>
        <div className="row">
          <div className="col-md-6 -aligner-wrapper mt-15">
            <Doughnut data={{ datasets: [dataset], labels }} height={160} options={options} />
            <div
              className="wrapper d-flex flex-column justify-content-center absolute absolute-center middle-block"
              style={{
                position: "absolute",
                top: "50%",
                right: "0",
                left: "0",
                margin: "auto",
                transform: "translateY(-50%)",
              }}
            >
              <div className="d-block text-center">
                <h4 className="fs-20">
                  <NumberFormat displayType="text" thousandSeparator={" "} value={totalValue} />
                </h4>
                <small className="text-muted">
                  <IntlMessages id="total" />
                </small>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-2 -legend-wrapper">
            <div className="row">
              {_.map(columnsStatuses, (statuses, column) => {
                return (
                  <div className="col" key={column}>
                    {_.map(statuses, ({ status, index }, position) => {
                      const value = _.get(dataset.data, index) || 0;
                      const label = labels[index];
                      const color = _.get(dataset.backgroundColor, index);
                      const percent = totalValue > 0 ? _.round((value / totalValue) * 100, 2) : 0;
                      return (
                        <div
                          key={position}
                          className="d-flex flex-column justify-content-center mb-3 -col-6 -col-md-12"
                        >
                          <div className="d-flex align-items-center">
                            <i className="fas fa-circle  mt-1 mr-2" style={{ color }} />
                            <h4 className="mb-0 text-dark fs-20">
                              <NumberFormat
                                displayType="text"
                                thousandSeparator={" "}
                                value={value}
                              />
                              {props.showPercent && (
                                <span className="ml-2 text-muted">({percent} %)</span>
                              )}
                            </h4>
                          </div>
                          <small className="text-muted ml-3">{label}</small>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusesPieChart;
