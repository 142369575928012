/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { PageTitle } from "components/standart";
import { LocalesValues } from "components/standart";

import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { Alert } from "reactstrap";

import { adminAPI } from "redux/api";
import { SwitchValue } from "components/special/forms";

import { apiUrls, typesForm } from "./init";
const { api } = adminAPI.actions;

const TypeForm = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs, type, setType } = props;
  const params = useParams();
  const [dataSaved, setDataSaved] = useState(false);
  const [error, setError] = useState(false);

  const changeContent = setOnChange(type, setType);
  breadCrumbs.push({
    title: <IntlMessages id="Www.Contents.Form" />,
    url,
  });

  const successAlertRef = useRef(null);

  const saveHandler = () => {
    const onSuccess = (body) => {
      setType(body);
      setDataSaved(true);
      successAlertRef.current.scrollIntoView();
    };

    const onRequest = () => {
      setDataSaved(false);
      setError(false);
    };

    const onFailure = ({ message }) => {
      setDataSaved(false);
      setError(message);
    };

    props.saveType({ params, body: type }, { onSuccess, onFailure, onRequest });
  };

  return (
    <div>
      <PageTitle {...breadCrumbs} />

      <div className="row">
        <div className="col-12">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                <Alert
                  className="alert-success"
                  isOpen={dataSaved}
                  toggle={() => setDataSaved(false)}
                >
                  <IntlMessages id="saved" />
                </Alert>
              </div>

              {error && (
                <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
                  <IntlMessages id={error} />
                </Alert>
              )}

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Types.name" />
                </label>
                <div className="col-8">
                  <input
                    name="name"
                    value={type.name || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="localesName" />

                  <LocalesValues.AddButton
                    className="btn-sm float-right"
                    values={type.localesName}
                    changeValues={(localesName) => setType({ ...type, localesName })}
                  />
                </label>
                <div className="col">
                  <LocalesValues
                    values={type.localesName}
                    changeValues={(localesName) => setType({ ...type, localesName })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Types.isDigital" />
                </label>
                <div className="col-8 my-auto">
                  <SwitchValue value={type.isDigital} name="isDigital" onChange={changeContent} />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="tables.enabled" />
                </label>
                <div className="col-8 my-auto">
                  <SwitchValue value={type.enabled} onChange={changeContent} />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-4 col-auto">
                  <button className="form-control c-primary" type="button" onClick={saveHandler}>
                    <IntlMessages id="save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButton = (props) => {
  const { type } = props;
  return (
    <Link to={typesForm(type)} className={`btn btn-secondary btn-sm ${props.className}`}>
      <i className="fa fa-edit" />
    </Link>
  );
};

export default connect(null, {
  saveType: api.patch(apiUrls.typesInfo),
})(TypeForm);
