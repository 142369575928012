/** форма бренда
 *
 */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { FloatQuotesCategories, FloatQuotesTopics } from "components/special/contents";
import { setOnChange } from "components/special";
import { LocalesValues } from "components/standart";

import ReactQuill from "react-quill";
import IntlMessages from "util/intlMessages";

const ContentData = (props) => {
  const { content, setContent } = props;
  const changeContent = setOnChange(content, setContent);

  return (
    <>
      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Contents.text" />
          <LocalesValues.AddButton
            className="btn-sm float-right"
            values={content.localesText}
            changeValues={(localesText) => setContent({ ...content, localesText })}
          />
        </label>
        <div className="col">
          <LocalesValues
            values={content.localesText}
            isHtml={false}
            changeValues={(localesText) => setContent({ ...content, localesText })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Contents.category" />
        </label>
        <div className="col">
          <FloatQuotesCategories
            _id={content.categoryId}
            className="btn-sm"
            showTitle={true}
            showCount={false}
            multi={false}
            successHandler={([categoryId]) => {
              setContent({ ...content, categoryId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Contents.topic" />
        </label>
        <div className="col">
          {content.categoryId ? (
            <FloatQuotesTopics
              _id={content.topicId}
              query={{ categoryId: content.categoryId }}
              className="btn-sm"
              showTitle={true}
              showCount={false}
              multi={false}
              successHandler={([topicId]) => {
                setContent({ ...content, topicId });
              }}
            />
          ) : null}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Contents.tag" />
        </label>
        <div className="col">
          <input
            name="tagName"
            value={content.tagName || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, {})(ContentData);
