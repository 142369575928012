import _ from "lodash";
import React, { useState } from "react";
import IntlMessages from "util/intlMessages";
import { history } from "redux/store";
import Select from "react-select";
import DatePicker from "react-datepicker";

import { ButtonDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { onEnter } from "components/special";

const Finder = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Dropdown
      className={props.className}
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
    >
      <DropdownToggle className={`btn-sm ${_.trim(props.isActive) ? `c-info` : `btn-secondary`}`}>
        <i className={`fa ${props.icon || "fa-search-plus"}`} />
      </DropdownToggle>
      <DropdownMenu style={{ padding: 0 }}>{props.children}</DropdownMenu>
    </Dropdown>
  );
};

Finder.input = (props) => {
  const { query, setQuery } = props;

  const onChangeHandler = (event) => {
    event.preventDefault();
    const { value } = event.currentTarget;
    setQuery({ ...query, [props.name]: value });
  };

  return (
    <input
      type="text"
      defaultValue={_.get(query, props.name) || ""}
      onBlur={onChangeHandler}
      // onKeyDown={onEnter((event) => { event.currentTarget.blur() })}
      onKeyDown={onEnter(onChangeHandler)}
      placeholder={props.placeholder}
      className="form-control"
    />
  );
};

Finder.datePicker = (props) => {
  const { query, setQuery } = props;

  const dateFormat = props.withTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy ";

  const value = _.get(query, props.name);
  return (
    <DatePicker
      selected={value ? new Date(value) : ""}
      dateFormat={dateFormat}
      popperPlacement="bottom-start"
      onChange={(value) => setQuery({ ...query, [props.name]: value ? value.toJSON() : undefined })}
      placeholderText={props.placeholder}
      className="form-control"
      calendarClassName="custom-calender-class"
    />
  );
};

Finder.select = (props) => {
  const { query, setQuery, mask, source } = props;
  const isMulti = !!props.multi;
  const options = () =>
    _.map(source, (value, index) => {
      if (_.isObject(mask) && _.isObject(value)) {
        return { value: value[mask.value], label: value[mask.label] };
      } else if (_.isString(mask)) {
        return { value, label: <IntlMessages id={`${mask}.${value}`} /> };
      }
    });

  const defaultValues = () => {
    const values = _.split(query[props.name], ",");
    return _.filter(options(), (option) => _.indexOf(values, option.value) >= 0);
  };

  const onChangeHandler = (selectedOptions) => {
    const value = isMulti ? _.map(selectedOptions, ({ value }) => value) : selectedOptions.value;
    setQuery({ ...query, [props.name]: value });
  };

  return (
    <Select
      // size="sm"
      isMulti={isMulti}
      name={props.name}
      options={options()}
      defaultValue={defaultValues()}
      placeholder={<IntlMessages id={mask} />}
      onChange={onChangeHandler}
    />
  );
};

const Search = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  return (
    <ButtonDropdown className="btn-secondary rounded" isOpen={dropdownOpen} toggle={toggleDropdown}>
      {/* 
      <span
        onClick={() =>
          history.push(`${props.url}?${qs.stringify(props.query)}`)
        }
      >
        <i className={`fa fa-search btn-sm`} />
      </span>
      */}
      <span className="btn-sm btn-secondary" onClick={() => history.push(`${props.url}`)}>
        <i className={`fa fa-ban`} />
      </span>

      {props.children && (
        <>
          <DropdownToggle caret className="btn-sm btn-secondary" />
          <DropdownMenu style={{ minWidth: "auto" }} right className="p-0">
            <DropdownItem className="p-0"></DropdownItem>
            {props.children}
          </DropdownMenu>
        </>
      )}
    </ButtonDropdown>
  );
};
Finder.search = Search;

export default Finder;
