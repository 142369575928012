/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import adminAPI from "redux/api/admin";
import IntlMessages from "util/intlMessages";

import { apiUrls } from "./init";

const { api } = adminAPI.actions;

export const StartButton = (props) => {
  const { job, refreshTable } = props;

  const startHandler = () => {
    const params = { jobId: job._id };
    if (job.status === "new") {
      props.startJob(
        { params },
        {
          onSuccess() {
            toggleModal();
            refreshTable();
          },
        }
      );
    }
    if (job.status === "completed") {
      props.restartJob(
        { params },
        {
          onSuccess() {
            toggleModal();
            refreshTable();
          },
        }
      );
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  return job.status !== "active" && job.enabled ? (
    <>
      <button className="btn btn-secondary btn-sm ml-1" onClick={toggleModal}>
        {job.status === "new" && <i className="fa fa-play" />}
        {job.status === "completed" && <i className="fa fa-sync" />}
      </button>
      {modalOpen && (
        <Modal size="md" isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Запустить автоперевод?</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col">
                <button className="btn c-primary" onClick={startHandler}>
                  <IntlMessages id="ok" />
                </button>
              </div>
              <div className="col">
                <button className="btn btn-secondary" onClick={toggleModal}>
                  <IntlMessages id="cancel" />
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  ) : null;
};

export default connect(null, {
  startJob: api.post(apiUrls.jobStart),
  restartJob: api.post(apiUrls.jobRestart),
})(StartButton);
