/** форма бренда
 *
 */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { FloatGradients } from "components/special/contents";
import { setOnChange } from "components/special";
import { LocalesValues } from "components/standart";

import ReactQuill from "react-quill";
import IntlMessages from "util/intlMessages";

const ContentData = (props) => {
  const { content, setContent } = props;
  const changeContent = setOnChange(content, setContent);

  return (
    <>
      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Contents.gradient" />
        </label>
        <div className="col">
          <FloatGradients
            _id={content.gradientId}
            className="btn-sm"
            showTitle={true}
            showCount={false}
            multi={false}
            successHandler={([gradientId]) => {
              setContent({ ...content, gradientId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Contents.tag" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="tagName"
            value={content.tagName || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>
      
      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Contents.text" />
          <LocalesValues.AddButton
            className="btn-sm float-right"
            values={content.localesText}
            changeValues={(localesText) => setContent({ ...content, localesText })}
          />
        </label>
        <div className="col">
          <LocalesValues
            values={content.localesText}
            isHtml={true}
            changeValues={(localesText) => setContent({ ...content, localesText })}
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, {})(ContentData);
