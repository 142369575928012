import _ from "lodash";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useRouteMatch } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import qs from "query-string";
import { history } from "redux/store";

import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { PageTitle } from "components/standart";
import SettingsCommon from "./Common";
import SettingsPremiums from "./Premiums";
import SettingsNotifications from "./Notifications";
import SettingsInvites from "./Invites";
import SettingsShop from "./Shop";

const Settings = (props) => {
  const parts = [
    { name: "Common", component: () => <SettingsCommon /> },
    { name: "Shop", component: () => <SettingsShop /> },
    { name: "Premiums", component: () => <SettingsPremiums /> },
    { name: "Invites", component: () => <SettingsInvites /> },
    { name: "Notifications", component: () => <SettingsNotifications /> },
  ];

  const { url } = useRouteMatch();
  const { breadCrumbs } = props;

  const [currentPart, setCurrentPart] = useState(parts[0].name);

  const setCurrentPartHandler = (part) => () => {
    history.replace(`?part=${part}`);
  };

  useEffect(() => {
    const { part } = qs.parse(history.location.search);
    part && setCurrentPart(part);
  }, [history.location.search]);

  breadCrumbs.push({
    title: <IntlMessages id="Www.Settings" />,
    url,
  });

  return (
    <>
      <PageTitle {...breadCrumbs} />

      <Nav tabs style={{ cursor: "pointer" }}>
        {_.map(parts, (part, index) => {
          return (
            <Fragment key={index}>
              <NavItem>
                <span
                  className={`nav-link ${currentPart === part.name ? "active" : ""}`}
                  onClick={setCurrentPartHandler(part.name)}
                >
                  {part.name}
                </span>
              </NavItem>
            </Fragment>
          );
        })}
      </Nav>

      {_.map(parts, (part, index) => {
        return currentPart === part.name ? (
          <Fragment key={index}>{part.component()}</Fragment>
        ) : null;
      })}
    </>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(Settings);
