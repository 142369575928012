import _ from "lodash";
import React, { useState, useEffect } from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import ItemsTable from "./Table";

import PageTitle, { BreadCrumbs } from "components/standart/PageTitle";

import AddItem from "./Add";
import ItemInfo from "./Info";
import ItemsCategories from "./Categories";
import ItemsEmits from "./Emits";
import ItemsTypes from "./Types";
import { path as categoriesPath } from "./Categories/init";
import { path as emitsPath } from "./Emits/init";
import { path as typesPath } from "./Types/init";
import { path } from "./init";

const Items = (props) => {
  const { url, path } = useRouteMatch();

  const { breadCrumbs = new BreadCrumbs() } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Items" />,
    url,
    actions: [<AddItem />],
  });

  return (
    <Switch>
      <Route exact path={`${path}/list`}>
        <PageTitle {...breadCrumbs} />

        <ItemsTable />
      </Route>

      <Route exact path={path} render={() => <Redirect to={`${url}/list`} />} />

      <Route path={`${path}${categoriesPath}`}>
        <ItemsCategories {...{ breadCrumbs }} />
      </Route>

      <Route path={`${path}${emitsPath}`}>
        <ItemsEmits {...{ breadCrumbs }} />
      </Route>

      <Route path={`${path}${typesPath}`}>
        <ItemsTypes {...{ breadCrumbs }} />
      </Route>

      <Route path={`${path}/item_:item_id`}>
        <ItemInfo {...{ breadCrumbs }} />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

export default { path, component: Items };
