import { path as parentPath } from "../init";

export const path = "/demo_customers";

export const apiUrls = {
  list: "/api/special/demo_customers",
  info: "/api/special/demo_customers/demo_{demoId}",
};

export const demoInfo = (demo) => {
  return `${parentPath}${path}/demo_${demo._id}`;
};

export const demoForm = (demo) => {
  return `${demoInfo(demo)}/form`;
};
