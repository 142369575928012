import _ from "lodash";
import React, { useState, useEffect } from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import ContentTable from "./Table";
import { PageTitle } from "components/standart";
import AddContent from "./Add";
import ContentInfo from "./Info";

const AspectsQuotes = (props) => {
    const { url, path } = useRouteMatch();
    const { breadCrumbs } = props;

    const contentInfo = (quote) => {
        return `${url}/quote_${quote._id}`;
    };

    const contentForm = (content) => {
        return `${contentInfo(content)}/form`;
    };

    breadCrumbs.push({
        title: <IntlMessages id="Contents.AspectsQuotes" />,
        url,
        actions: [<AddContent {...{ contentForm }} />],
    });

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <PageTitle {...breadCrumbs} />

                <ContentTable {...{ contentInfo, contentForm }} />
            </Route>

            <Route path={`${path}/quote_:quote_id`}>
                <ContentInfo {...{ breadCrumbs, contentInfo, contentForm }} />
            </Route>

            <Route component={Error400} />
        </Switch>
    );
};

export default AspectsQuotes;
