import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { FloatMarketplaces } from "components/special/contents";

export const MarketplacesUrl = (props) => {
  const { values = [], changeValues } = props;
  const intl = useIntl();
  return (
    <div>
      {_.map(values, (elem, index) => {
        return (
          <div className="row mb-1" key={index}>
            <div className="col-3">
              <FloatMarketplaces
                _id={elem.marketplaceId}
                showTitle={true}
                showCount={false}
                multi={false}
                successHandler={([marketplaceId]) => {
                  Object.assign(values[index], { marketplaceId });
                  changeValues(values);
                }}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder={intl.formatMessage({ id: "url" })}
                value={elem.url || ""}
                onChange={({ target }) => {
                  Object.assign(values[index], { url: target.value });
                  changeValues(values);
                }}
              />
            </div>
            <div className="col-1">
              <MarketplacesUrl.RemoveButton {...props} index={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

MarketplacesUrl.AddButton = (props) => {
  const { values = [], changeValues } = props;
  const addValue = () => {
    changeValues(values.concat([{ marketplaceId: null, url: null }]));
  };
  return (
    <button className={`btn btn-success ${props.className}`} onClick={addValue}>
      <i className="fa fa-plus" />
    </button>
  );
};

MarketplacesUrl.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

export default MarketplacesUrl;
