import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { UncontrolledTooltip } from "reactstrap";
import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminAPI } from "redux/api";
import { apiUrls } from "./init";

const { REACT_APP_BACKEND_API } = process.env;
const { api } = adminAPI.actions;

const CategoryQrCode = (props) => {
  const { category } = props;
  const categoryId = category._id;
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);
  const qrImageUrl = `${REACT_APP_BACKEND_API}/qrcode`;

  const [copied, setCopied] = useState(false);
  const [url, setUrl] = useState(null);
  const [qrCopied, setQrCopied] = useState(false);
  const tooltipTimeout = useRef();

  useEffect(() => {
    if (openModal) {
      props.getQrUrl(
        { query: { categoryId } },
        {
          onSuccess({ url }) {
            setUrl(url);
          },
        }
      );
    }
  }, [openModal]);

  useEffect(() => {
    if (copied) {
      tooltipTimeout.current = setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  useEffect(() => {
    if (qrCopied) {
      tooltipTimeout.current = setTimeout(() => setQrCopied(false), 3000);
    }
  }, [qrCopied]);

  return (
    <>
      <button className="btn btn-sm btn-info" onClick={toggleModal}>
        <i className="fa fa-qrcode" />
      </button>

      {openModal && (
        <Modal isOpen={openModal} centered size="md" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="Items.Categories.QrCode" />
          </ModalHeader>
          <ModalBody>
            <div className="roe-card-style  mt-3">
              <div className="roe-card-header">
                QR-код{" "}
                <a
                  href={`${qrImageUrl}/download.svg?categoryId=${category._id}`}
                  className="btn btn-sm btn-info mx-2"
                >
                  <i className="fa fa-file-download mr-2" />
                  svg
                </a>
                <a
                  href={`${qrImageUrl}/download.png?categoryId=${category._id}`}
                  className="btn btn-sm btn-primary mr-2"
                >
                  <i className="fa fa-file-image mr-2" />
                  png
                </a>
                <span>
                  <CopyToClipboard text={`${url}`} onCopy={() => setQrCopied(true)}>
                    <span
                      className="btn -btn-sm float-right -btn-secondary"
                      style={{ cursor: "pointer" }}
                      id="copy-image-url"
                    >
                      <i className="fal fa-copy" />
                    </span>
                  </CopyToClipboard>
                  <UncontrolledTooltip placement="top" isOpen={qrCopied} target={"copy-image-url"}>
                    Ссылка скопирована
                  </UncontrolledTooltip>
                </span>
              </div>
              <div className="roe-card-body">
                <img src={`${qrImageUrl}?categoryId=${category._id}`} width="200" />
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  getQrUrl: api.get("/qrcode/url"),
})(CategoryQrCode);
