import React from "react";
import IntlMessages from "util/intlMessages";

const SwitchValue = (props) => {
  const { name = "enabled", valueOn = true, valueOff = false } = props;

  return (
    <>
      <div className="pretty p-switch p-toggle p-fill">
        <input
          name={name}
          value={props.value === valueOn ? valueOff : valueOn}
          type="checkbox"
          onChange={props.onChange}
          defaultChecked={props.value === valueOn}
        />

        <div className="state p-off">
          <label>{props.labelOff || <IntlMessages id="no" />}</label>
        </div>

        <div className="state p-on">
          <label>{props.labelOn || <IntlMessages id="yes" />}</label>
        </div>
      </div>
    </>
  );
};

export default SwitchValue;
