/**
 * генератор запросов к СloClo API
 * возвращает класс, который собирает структуру для генерации actions, reducer и middleware
 *
 */
import _ from "lodash";
import qs from "query-string";
import { combineReducers } from "redux";
import { push } from "react-router-redux";

// здесь будут уже сконфигурированные редюсеры
import { SUCCESS, REQUEST, FAILURE } from "./init";
import adminReducers from "./reducers";
import session from "./session";
import sockets from "./sockets";

export default adminReducers;

export const adminSockets = sockets;

// название токена
export const accessToken = "adminToken";

// типовой редюсер для API
export function reducer(state = {}, action) {
  console.log("do regular reducer", action);
  const { payload } = action;
  switch (action.type) {
    case SUCCESS:
      return { ...state, body: payload.body };

    case FAILURE:
      return {
        ...state,
        error: _.get(payload, "response.message") || payload.statusText,
      };

    case REQUEST:
      return { ...state, timestamp: new Date() };

    default:
      return state;
  }
}

export const adminAPIReducer = combineReducers({
  api: reducer,
  ...adminReducers.reducers,
});
// export const adminAPIReducer = reducer;

export const adminAPIMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const { payload } = action;

  // если по какой-то причине вернулся 401, то полностью закроем сессию и удалим токен
  if (401 == _.get(payload, "status")) {
    store.dispatch(session.actions.logout());
    store.dispatch(push("/"));
  }

  // если по какой-то причине вернулся 409, то полностью обновим страницу
  // это означает, что умерла кука сессии, или сломался отпечаток клиента
  // if (409 === _.get(payload, "status")) {
  //   document.location.reload();
  // }

  return result;
};

export const middlewares = [adminAPIMiddleware, ...adminReducers.middlewares];

export const applyUrlParams = (url, params = {}, query = {}) => {
  // todo replace keys in url by params
  _.each(params, (value, key) => {
    url = url.replace(`{${key}}`, value);
  });
  return url + "?" + qs.stringify(query);
};

export const BACKEND_API = process.env.REACT_APP_BACKEND_API;

export const autoDetect = (url, method) => {
  let route = {};

  if (method === "AUTO") {
    route = _.get(window.cloclo_routes, url);
    if (!route) {
      throw console.log(`no auto route ${url}`);
    }
  }
  // /*
  route.path && (url = route.path);
  route.method && (method = route.method);

  return { url, method };
};

export const generateUrl = (url, { params, query } = {}) => {
  return `${BACKEND_API}${applyUrlParams(url, params, query)}`;
};

export const corsUrl = (url) => {
  const CORS_ANYWHERE_URL =
    process.env.REACT_APP_CORS_ANYWHERE || "https://cors-anywhere.herokuapp.com";
  return `${CORS_ANYWHERE_URL}/${url}`;
};
