/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FloatLocales } from "components/special/contents";
import { setOnChange } from "components/special";
import { SwitchValue } from "components/special/forms";
import { FloatCustomers } from "components/special/customers";

import IntlMessages from "util/intlMessages";
import { adminAPI, setSuccess } from "redux/api";
import { apiUrls } from "./init";
import Select from "react-select";

const { api } = adminAPI.actions;

const DemoData = (props) => {
  const { demo, setDemo } = props;

  return (
    <>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Locales" />
        </label>
        <div className="col">
          <FloatLocales
            _id={demo.localeId}
            className="btn-sm"
            showTitle={true}
            showCount={false}
            multi={false}
            successHandler={([localeId]) => {
              setDemo({ ...demo, localeId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Customers" />
        </label>
        <div className="col">
          <FloatCustomers
            _id={demo.customerId}
            className="btn-sm"
            showTitle={true}
            showCount={false}
            multi={false}
            successHandler={([customerId]) => {
              setDemo({ ...demo, customerId });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getEnv: api.get(apiUrls.jobsEnv),
})(DemoData);
