import _ from "lodash";
import React, { useState, useEffect } from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import JobsTable from "./Table";
import { PageTitle } from "components/standart";
import AddJob from "./Add";
import JobsInfo from "./Info";

const TranslatesJobs = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Special.Translates" />,
    url,
    actions: [<AddJob />],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />

        <JobsTable />
      </Route>

      <Route path={`${path}/job_:jobId`}>
        <JobsInfo {...{ breadCrumbs }} />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

export default TranslatesJobs;
