import _ from "lodash";
import React, { useState, useEffect } from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import Table from "./Table";
import { PageTitle } from "components/standart";
import AddDemo from "./Add";
import Info from "./Info";

const DemoCustomers = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Special.DemoCustomers" />,
    url,
    actions: [<AddDemo />],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />

        <Table />
      </Route>

      <Route path={`${path}/demo_:demoId`}>
        <Info {...{ breadCrumbs }} />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

export default DemoCustomers;
