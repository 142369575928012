/**
 * генератор запросов к СloClo API
 * возвращает класс, который собирает структуру для генерации actions, reducer и middleware
 *
 */
import _ from "lodash";
import qs from "query-string";
import { combineReducers } from "redux";

// здесь будут уже сконфигурированные редюсеры
import { SUCCESS, REQUEST, FAILURE } from "./init";
import filesReducers from "./reducers";

export default filesReducers;

// название токена
export const accessToken = "adminToken";

// типовой редюсер для API
export function reducer(state = {}, action) {
  console.log("do regular reducer", action);
  const { payload } = action;
  switch (action.type) {
    case SUCCESS:
      return { ...state, body: payload.body };

    case FAILURE:
      return {
        ...state,
        error: _.get(payload, "response.message") || payload.statusText,
      };

    case REQUEST:
      return { ...state, timestamp: new Date() };

    default:
      return state;
  }
}

export const filesAPIReducer = combineReducers({
  api: reducer,
  ...filesReducers.reducers,
});

export const middlewares = [...filesReducers.middlewares];

export const applyUrlParams = (url, params = {}, query = {}) => {
  // todo replace keys in url by params
  _.each(params, (value, key) => {
    url = url.replace(`{${key}}`, value);
  });
  return url + "?" + qs.stringify(query);
};

export const FILES_API = process.env.REACT_APP_FILES_API;

export const autoDetect = (url, method) => {
  let route = {};

  if (method === "AUTO") {
    route = _.get(window.cloclo_routes, url);
    if (!route) {
      throw console.log(`no auto route ${url}`);
    }
  }
  // /*
  route.path && (url = route.path);
  route.method && (method = route.method);

  return { url, method };
};

export const generateUrl = (url, { params, query } = {}) => {
  return `${FILES_API}${applyUrlParams(url, params, query)}`;
};

export const corsUrl = (url) => {
  const CORS_ANYWHERE_URL =
    process.env.REACT_APP_CORS_ANYWHERE || "https://cors-anywhere.herokuapp.com";
  return `${CORS_ANYWHERE_URL}/${url}`;
};
