import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import IntlMessages from "util/intlMessages";
import { Alert } from "reactstrap";
import Select from "react-select";
import { SwitchValue } from "components/special/forms";
import { setOnChange } from "components/special";
import adminAPI from "redux/api/admin";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const LoginData = (props) => {
  const { envStore } = props;
  const { login, setLogin, saveHandler, requestHandler = new Function() } = props;
  const changeLogin = setOnChange(login, setLogin);

  const [error, setError] = useState(false);
  const [validLogin, setValidLogin] = useState(false);

  const saveLogin = () => {
    const onFailure = ({ message }) => {
      setError(message);
    };
    const onRequest = () => {
      requestHandler();
      setError(false);
    };
    saveHandler({ body: login }, { onFailure, onRequest });
  };

  const timeout = useRef(null);

  const checkValidLogin = () => {
    const { _id } = login;
    props.checkLogin(
      { query: { _id }, body: login },
      {
        onSuccess() {
          setError(false);
          setValidLogin(true);
        },
        onFailure({ message }) {
          setError(message);
        },
        onRequest() {
          setValidLogin(false);
        },
      }
    );
  };

  // проверим, что логин доступн
  useEffect(() => {
    clearTimeout(timeout.current);
    setValidLogin(false);
    if (login.value && login.type) {
      timeout.current = setTimeout(() => {
        checkValidLogin();
      }, 500);
    }
  }, [login]);
  const typesOptions = _.values(_.get(envStore, "loginTypes")).map((value) => ({
    value,
    label: <IntlMessages id={`Logins.type.${value}`} />,
  }));

  return (
    <div>
      {error && (
        <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
          <IntlMessages id={error} />
        </Alert>
      )}

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Logins.value" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="value"
            value={login.value || ""}
            onChange={changeLogin}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Logins.type" />
        </label>
        <div className="col-8">
          <Select
            isDisabled={!!login._id}
            options={typesOptions}
            required
            value={typesOptions.filter(({ value }) => value === login.type)}
            onChange={({ value: type }) => setLogin({ ...login, type })}
            placeholder={<IntlMessages id="Logins.type" />}
          />
        </div>
      </div>


      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="tables.enabled" />
        </label>
        <div className="col-8 my-auto">
          <SwitchValue value={login.enabled} onChange={changeLogin} />
        </div>
      </div>

      <button
        type="button"
        disabled={!validLogin}
        onClick={saveLogin}
        className="btn c-primary form-button"
      >
        <IntlMessages id={"save"} />
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  checkLogin: api.post(apiUrls.checkLogins),
})(LoginData);
