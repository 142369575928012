import adminAPI from "./admin";
import filesAPI, { FILES_API } from "./files";

export { adminAPI, filesAPI };

// быстрое присваивание результат в указанный сеттер данных
export const setSuccess = (setData) => ({
  onSuccess(body) {
    setData(body);
  },
});

/** ссылка для файлов */
export function fileUrl(fileId, type = "stream") {
  return `${FILES_API}/file_${fileId}/${type}`;
}
