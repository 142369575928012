import _ from "lodash";
import React, { useState, useEffect } from "react";
import IntlMessages from "util/intlMessages";
import { LocalesValues } from "components/standart";

import { setOnChange } from "../index";
import { FloatAspectsCategories, FloatTags } from "../contents";

const SocialBlockAction = (props) => {
  const { attrName, data, setData } = props;
  const [actionData, setActionData] = useState({ ...data[attrName] });

  useEffect(() => {
    setData({ ...data, [attrName]: actionData });
  }, [actionData]);

  const changeData = setOnChange(actionData, setActionData);

  const attrsData = [
    // { attr: "imageTextUp" },
    // { attr: "imageTextDown" },
    { attr: "messageMainText", multiLine: true },
    { attr: "messageButtonText" },

    // { attr: "notificationTextUp" },
    // { attr: "notificationTextDown" },
    { attr: "notificationMessageText", multiLine: true },
    { attr: "notificationMessageButton" },

    { attr: "actionButtonText" },
    { attr: "confirmTitleText" },
    { attr: "confirmMainText", multiLine: true },
    { attr: "confirmButtonText" },
    { attr: "actionSuccessTitle" },
    { attr: "actionSuccessText", multiLine: true },
    { attr: "actionSuccessButton" },
    { attr: "actionFailureTitle" },
    { attr: "actionFailureText", multiLine: true },
    { attr: "actionFailureButton" },
  ];
  return (
    <>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id={`SocialActionBlock.imageCategoryId`} />
        </label>
        <div className="col-8">
          <FloatAspectsCategories
            _id={actionData.imageCategoryId}
            showTitle={true}
            showCount={false}
            multi={false}
            successHandler={([imageCategoryId]) => {
              setActionData({ ...actionData, imageCategoryId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id={`SocialActionBlock.notificationImageCategoryId`} />
        </label>
        <div className="col-8">
          <FloatAspectsCategories
            _id={actionData.notificationImageCategoryId}
            showTitle={true}
            showCount={false}
            multi={false}
            successHandler={([notificationImageCategoryId]) => {
              setActionData({ ...actionData, notificationImageCategoryId });
            }}
          />
        </div>
      </div>

      {attrsData.map((data, index) => {
        const { attr, multiLine } = data;
        return (
          <div key={index} className="form-group row">
            <div className="col-4 col-form-label">
              <IntlMessages id={`SocialActionBlock.${attr}`} />
              <LocalesValues.AddButton
                className="btn-sm float-right"
                values={actionData[attr]}
                changeValues={(values) => setActionData({ ...actionData, [attr]: values })}
              />
            </div>
            <div className="col-8">
              <LocalesValues
                values={actionData[attr]}
                isHtml={false}
                rows={multiLine ? 8 : 1}
                changeValues={(values) => setActionData({ ...actionData, [attr]: values })}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SocialBlockAction;
