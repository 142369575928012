import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { setOnChange } from "components/special";
import adminAPI from "redux/api/admin";

import { apiUrls, itemsForm } from "./init";
import { FloatCategories } from "components/special/items";

const { api } = adminAPI.actions;

const AddItem = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [newItem, setNewItem] = useState({});
  const dispatch = useDispatch();

  const submitHandler = (values) => {
    const onSuccess = (body) => {
      dispatch(push(itemsForm(body)));
    };
    props.addItem({ body: values }, { onSuccess });
  };

  useEffect(() => {
    setNewItem({});
  }, [openModal]);

  return (
    <>
      <FloatCategories
        _id={null}
        showTitle={true}
        showCount={false}
        multi={false}
        header={<IntlMessages id="Items.Add" />}
        buttonComponent={({ toggleModal }) => (
          <i className="fa fa-plus-square" onClick={toggleModal} />
        )}
        successHandler={([categoryId]) => {
          categoryId && submitHandler({ categoryId });
        }}
      />
    </>
  );
};

export default connect(null, {
  addItem: api.post(apiUrls.list),
})(AddItem);
