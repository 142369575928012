/** модальное окно для выбора категорий в требуемых случаях */
import _ from "lodash";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import { onEnter, setOnChange } from "components/special";
import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatTypesModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Items.Types" />}
      {...props}
      head={(searchTypes, searchQuery, setSearchQuery) => {
        return (
          <>
            <th colSpan="2">
              <IntlMessages id="Items.Types.name" />
            </th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(type, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!type.enabled ? "text-strike" : ""}>
            {type.name}
          </td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatTypes = (props) => {
  return <FloatTypesModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/items/types"),
})(FloatTypes);
