/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import PageTitle from "components/standart/PageTitle";
import ReactQuill, { Quill } from "react-quill";
import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { Alert } from "reactstrap";
import { FloatLocales } from "components/special/contents";

import ImageUploader from "quill-image-uploader";

// import ReactQuillImageUploader, { saveImageSrc } from "react-quill-image-uploader";

import { adminAPI, fileUrl } from "redux/api";
import { SwitchValue } from "components/special/forms";
import { apiUrls } from "../init";
const { api } = adminAPI.actions;

Quill.register("modules/imageUploader", ImageUploader);

const FaqForm = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs, faq, setFaq } = props;
  const params = useParams();
  const [dataSaved, setDataSaved] = useState(false);

  const changeFaq = setOnChange(faq, setFaq);
  breadCrumbs.push({
    title: <IntlMessages id="Www.Faq.Form" />,
    url,
  });

  const successAlertRef = useRef(null);

  const saveHandler = () => {
    const onSuccess = (body) => {
      setFaq(body);
      setDataSaved(true);
      successAlertRef.current.scrollIntoView();
    };

    const onRequest = () => {
      setDataSaved(false);
    };

    const onFailure = () => {
      setDataSaved(false);
    };

    props.saveFaq({ params, body: faq }, { onSuccess, onFailure, onRequest });
  };

  const imageUploaderModule = {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        body.append("file", file);

        props.upload(
          { body },
          {
            onSuccess(body) {
              console.log(body);
              const [{ s3, location, _id }] = body;
              resolve(s3 ? location : fileUrl(_id, "image"));
            },
            onFailure({ message }) {
              reject(message);
            },
          }
        );
      });
    },
  };

  const modules = useRef({
    toolbar: [
      [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
      ["clean"],
    ],
    // imageUploader: imageUploaderModule,
  });

  return (
    <div>
      <PageTitle {...breadCrumbs} />

      <div className="row">
        <div className="col-12">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                <Alert
                  className="alert-success"
                  isOpen={dataSaved}
                  toggle={() => setDataSaved(false)}
                >
                  <IntlMessages id="saved" />
                </Alert>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Www.Faq.title" />
                </label>
                <div className="col-8">
                  <input
                    id="title"
                    name="title"
                    value={faq.title || ""}
                    onChange={changeFaq}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Www.Faq.ident" />
                </label>
                <div className="col-8">
                  <input
                    name="ident"
                    value={faq.ident || ""}
                    onChange={changeFaq}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div className="form-group row" style={{ height: 150 }}>
                <label className="col-4 col-form-label">
                  <IntlMessages id="Www.Faq.question" />
                </label>
                <div className="col-8">
                  <ReactQuill
                    value={faq.question || ""}
                    style={{ height: 100 }}
                    onChange={(question) => {
                      setFaq({ ...faq, question });
                    }}
                  />
                </div>
              </div>
              <div className="form-group row" style={{ height: 150 }}>
                <label className="col-4 col-form-label">
                  <IntlMessages id="Www.Faq.answer" />
                </label>
                <div className="col-8">
                  <ReactQuill
                    value={faq.answer || ""}
                    style={{ height: 100 }}
                    modules={modules.current}
                    onChange={(answer) => {
                      setFaq({ ...faq, answer });
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Locales" />
                </label>
                <div className="col-8">
                  <FloatLocales
                    _id={faq.localeId}
                    showTitle={true}
                    showCount={false}
                    multi={false}
                    successHandler={([localeId]) => {
                      setFaq({ ...faq, localeId });
                    }}
                  />

                  {/* <LocalesSelector value={faq.locale} setValue={changeFaq} /> */}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="tables.enabled" />
                </label>
                <div className="col-8  my-auto">
                  <SwitchValue value={faq.enabled} onChange={changeFaq} />
                </div>
              </div>
              <div className="form-group">
                <button
                  className="btn c-primary"
                  type="button"
                  disabled={!faq.title}
                  onClick={saveHandler}
                >
                  <IntlMessages id="save" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButton = (props) => {
  const { faqForm, faq } = props;
  return (
    <Link to={faqForm(faq)} className="btn btn-secondary btn-sm">
      <i className="fa fa-edit" />
    </Link>
  );
};

const ident = "Www.Faq.QA";
export default connect(null, {
  saveFaq: api.patch(apiUrls.faqInfo),
})(FaqForm);
