/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";

import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { Pager, FilterButton, Finder, TableLoading } from "components/standart";
import { history } from "redux/store";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { limit } from "variables";
import { apiUrls } from "../init";
const { api } = adminAPI.actions;

const FaqTable = (props) => {
  const { url, path } = useRouteMatch();
  const { localeId } = props;
  const [faqData, setFaqData] = useState({ total: 0 });
  const [query, setQuery] = useState({});

  const { faqInfo, faqForm } = props;

  useEffect(() => {
    setQuery({ limit, localeId, ...qs.parse(history.location.search) });
  }, [history.location, localeId]);

  useEffect(() => {
    const onSuccess = (body) => {
      setFaqData(body);
    };
    const onRequest = () => {
      setFaqData({ total: -1 });
    };

    query.limit && props.getFaq({ query }, { onSuccess, onRequest });
  }, [query]);

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return _.filter(result, _.size);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(faqData.data, result.source.index, result.destination.index);

    const faq_id = _.map(newOrder, ({ _id }) => _id);
    props.saveFaqPriorities(
      { body: { _id: faq_id }, query: { localeId } },
      {
        onSuccess() {
          console.log(new Date(), "save success");
        },
      }
    );
    setFaqData({ ...faqData, data: newOrder });
  };

  return (
    <>
      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="tables.title" />
            </th>
            <th>
              <IntlMessages id="Www.Faq.ident" />
            </th>
            <th>
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
            </th>
          </tr>
        </thead>
        {faqData.total < 0 && <TableLoading />}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {_.map(faqData.data, (faq, key) => (
                  <Draggable key={faq._id} draggableId={faq._id} index={key}>
                    {(provided, snapshot) => (
                      <tr
                        style={{
                          ...(snapshot.isDragging ? { display: "table" } : {}),
                        }}
                        // isDragging={snapshot.isDragging}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`${!faq.enabled ? "text-strike" : ""}`}
                      >
                        <td>
                          <Link to={faqInfo(faq)}>{faq.title}</Link>
                        </td>

                        <td>{faq.ident}</td>
                        <td>
                          <Link to={faqForm(faq)} className="btn btn-secondary btn-sm">
                            <i className="fa fa-edit" />
                          </Link>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>

        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={faqData.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getFaq: api.get(apiUrls.faq),
  saveFaqPriorities: api.post(apiUrls.faqPriorities),
})(FaqTable);
