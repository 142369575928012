import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { adminAPI, setSuccess } from "redux/api";

import DataPieChart from "../DataPieChart";
import StatusesPieChart from "../StatusesPieChart";
import DynamicLineChart from "../DynamicLineChart";
import DynamicGroupLineChart from "../DynamicGroupLineChart";
import IntlMessages from "util/intlMessages";

const { api } = adminAPI.actions;

const SummaryStats = (props) => {
  const { context_segment } = props;
  const [itemsCategoriesStats, setItemsCategoriesStats] = useState([]);
  const [payoutsStatusesStats, setPayoutsStatusesStats] = useState([]);
  const [paymentsDynamic, setPaymentsDynamic] = useState({});
  const [customersGendersDynamic, setCustomersGendersDynamic] = useState({});
  const [customersGendersSummary, setCustomersGendersSummary] = useState([]);
  const [customersAgeSummary, setCustomersAgeSummary] = useState([]);
  const [customersZodiacDynamic, setCustomerZodiacDynamic] = useState({});
  const [customersZodiacSummary, setCustomerZodiacSummary] = useState({});
  const [customersScoresSummary, setCustomerScoresSummary] = useState([]);
  const [itemsCategoriesDynamic, setItemsCategoriesDynamic] = useState({});
  const [paymentsSummary, setPaymentsSummary] = useState([]);
  const [paymentsCurrenciesSummary, setPaymentsCurrenciesSummary] = useState({});

  useEffect(() => {
    setPaymentsCurrenciesSummary(_.groupBy(paymentsSummary, "currencyName"));
  }, [paymentsSummary]);

  useEffect(() => {
    const query = {};
    props.getItemsStats({ query }, setSuccess(setItemsCategoriesStats));
    props.getCustomersScores({ query }, setSuccess(setCustomerScoresSummary));
    props.getPaymentsSummary({ query }, setSuccess(setPaymentsSummary));

    props.getCustomersAgeSummary({ query: {} }, setSuccess(setCustomersAgeSummary));
    props.getCustomersSummary(
      { query: { groupBy: "gender" } },
      setSuccess(setCustomersGendersSummary)
    );
    props.getCustomersSummary(
      { query: { groupBy: "astroSign" } },
      setSuccess(setCustomerZodiacSummary)
    );
    // props.getPayouts({ query }, setSuccess(setPayoutsStatusesStats));
    // props.getOrdersSummary({ query }, setSuccess(setOrdersSummary));
    // props.getOrdersPayments({ query }, setSuccess(setPaymentsSummary));
  }, []);

  const [scale, setScale] = useState("week");
  useEffect(() => {
    const query = { scale, context_segment };
    props.getPaymentsDynamic({ query }, setSuccess(setPaymentsDynamic));
    props.getItemsDynamic({ query }, setSuccess(setItemsCategoriesDynamic));
    props.getCustomersDynamic(
      { query: { ...query, groupBy: "gender" } },
      setSuccess(setCustomersGendersDynamic)
    );
    props.getCustomersDynamic(
      { query: { ...query, groupBy: "astroSign" } },
      setSuccess(setCustomerZodiacDynamic)
    );
  }, [scale, context_segment]);

  return (
    <div className="row">
      <div className="col-12 col-md-6 mb-3">
        <DataPieChart
          data={itemsCategoriesStats}
          value="count"
          label="category.name"
          showPercent={true}
          title="Dashboard.ItemsCategories"
          // legend="Partners"
        />
      </div>
      {/* {JSON.stringify(_.groupBy(paymentsSummary, "currencyName"))}; */}
      {_.map(_.keys(paymentsCurrenciesSummary), (currencyName, index) => {
        const currencySummary = paymentsCurrenciesSummary[currencyName];
        // return <div key={index}>{JSON.stringify(currencySummary)}</div>;
        return _.size(currencySummary) ? (
          <div className="col-12 col-md-6 pb-3" key={index}>
            <DataPieChart
              data={currencySummary}
              value="value"
              label="referenceName"
              labelPrefix="Dashboard.Payments.CurrencySummary"
              showPercent={true}
              title={
                <>
                  <IntlMessages id="Dashboard.Payments.CurrencySummary" values={{ currencyName }} />
                </>
              }
            />
          </div>
        ) : null;
      })}

      <div className="col-12 pb-3">
        <DynamicLineChart
          {...{ scale, setScale }}
          stats={{ ...itemsCategoriesDynamic?.dynamic }}
          graphs={[...(itemsCategoriesDynamic?.categories || [])]}
          label={`name`}
          title="Dashboard.ItemsCategories.Dynamic"
        />
      </div>

      <div className="col-12 pb-3">
        <DynamicLineChart
          {...{ scale, setScale }}
          stats={{ ...customersGendersDynamic?.dynamic }}
          graphs={[...(customersGendersDynamic?.genders || [])]}
          label={`Genders`}
          title="Dashboard.Customers.DynamicGender"
        />
      </div>
      <div className="col-12 pb-3">
        <DynamicLineChart
          {...{ scale, setScale }}
          stats={{ ...customersZodiacDynamic?.dynamic }}
          graphs={[...(customersZodiacDynamic?.astroSigns || [])]}
          label={`AstroSigns`}
          title="Dashboard.Customers.DynamicZodiac"
        />
      </div>

      <div className="col-12 pb-3">
        <DynamicGroupLineChart
          stats={paymentsDynamic?.dynamic || []}
          {...{ scale, setScale }}
          graphs={[...(paymentsDynamic?.services || [])]}
          groupByKey="paymentServiceId"
          subGroupByKey="currencyName"
          valueKey="value"
          label={(graph, subGroupValue) => `${graph.name} (${subGroupValue})`}
          title="Dashboard.Payments.Dynamic"
        />
      </div>

      <div className="col-lg-12 mb-3">
        <StatusesPieChart
          stats={_.keyBy(customersZodiacSummary.summary, "_id")}
          label="AstroSigns"
          showPercent={true}
          cols={2}
          statuses={[...(customersZodiacSummary.astroSigns || [])]}
          title="Dashboard.Customers.SummaryZodiac"
          columns
        />
      </div>

      <div className="col-lg-6 mb-3">
        <StatusesPieChart
          stats={_.keyBy(customersGendersSummary.summary, "_id")}
          label="Genders"
          showPercent={true}
          statuses={[...(customersGendersSummary.genders || [])]}
          title="Dashboard.Customers.SummaryGender"
        />
      </div>

      <div className="col-lg-6 mb-3">
        <DataPieChart
          data={customersAgeSummary}
          value="count"
          label="age"
          showPercent={true}
          title="Dashboard.Customers.SummaryAge"
          // legend="Funds"
        />
      </div>
      <div className="col-lg-6 mb-3">
        <DataPieChart
          data={customersScoresSummary}
          value="count"
          label="score"
          showPercent={true}
          title="Dashboard.Customers.ScoresSummary"
          resultValue={(data) =>
            _.round(
              _.sumBy(data, (stat) => +stat.count * +stat.score) /
                _.sumBy(data, (stat) => +stat.count),
              2
            )
          }
          // legend="Funds"
        />
      </div>
      {/* 
      <div className="col-12 col-md-6  pb-3">
        <DataPieChart
          data={payoutsStatusesStats}
          value="total"
          label="fund.name"
          title="Dashboard.Payouts"
          // legend="Funds"
        />
      </div>
       */}
      {/* 
      <div className="col-12 col-md-6 pb-3">
        <DataPieChart
          data={ordersStatusesStats}
          value="total"
          label="partner.name"
          title="Dashboard.Orders.Total"
          // legend="Partners"
        />
      </div>
       */}

      {/* 
      <div className="col-lg-6 mb-3">
        <StatusesPieChart
          stats={ordersSummary}
          label="Dashboard.KPI"
          statuses={["newCount", "notNewCount"]}
          title="Dashboard.KPI"
        />
      </div>
        */}
      {/* 
      <div className="col-lg-6 mb-3">
        <StatusesPieChart
          stats={paymentsSummary}
          label="Dashboard.Payments"
          statuses={["commission", "payouts", "waitForPayouts"]}
          title="Dashboard.Payments"
        />
      </div>
       */}
    </div>
  );
};

export default connect(null, {
  getItemsStats: api.get("/api/items/stats"),
  getItemsDynamic: api.get("/api/items/stats/dynamic"),
  getPaymentsDynamic: api.get("/api/payments/stats/dynamic"),
  getPaymentsSummary: api.get("/api/payments/stats/reference_summary"),
  getCustomersSummary: api.get("/api/customers/stats/summary"),
  getCustomersAgeSummary: api.get("/api/customers/stats/age_summary"),
  getCustomersDynamic: api.get("/api/customers/stats/dynamic"),
  getCustomersScores: api.get("/api/customers/stats/scores"),
  // getOrdersSummary: api.get("/api/orders/summary"),
  // getOrdersPayments: api.get("/api/orders/payments"),
})(SummaryStats);
