import { path as itemsPath } from "../init";

export const path = "/emits";

export const apiUrls = {
  emits: "/api/items/emits",
  emitInfo: "/api/items/emits/emit_{emitId}",
  emitItems: "/api/items/emits/emit_{emitId}/items",
};

export const emitsInfo = (emit) => {
  return `${itemsPath}${path}/emit_${emit._id}`;
};

export const emitsForm = (emit) => {
  return `${emitsInfo(emit)}/form`;
};
