import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Redirect, Switch, Link, useParams, useRouteMatch } from "react-router-dom";
import { Table } from "reactstrap";
import Lightbox from "react-image-lightbox";
import IntlMessages from "util/intlMessages";

import { Error400 } from "views/pages";

import PageTitle from "components/standart/PageTitle";
import { LoadingIcon, setOnChange } from "components/special";
import {
  FloatAspectsCategories,
  FloatAspectsValues,
  FloatMemes,
} from "components/special/contents";

import Form from "./Form";

import adminAPI from "redux/api/admin";
import { apiUrls, generateForm } from "./init";
const { api } = adminAPI.actions;

const GenerateInfo = (props) => {
  const { url, path } = useRouteMatch();

  const params = useParams();

  const [generate, setGenerate] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [settings, setSettings] = useState({});
  const [memeLoading, setMemesLoading] = useState(false);
  const [dataSaving, setDataSaving] = useState(false);
  const [memeSaving, setMemesSaving] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [memes, setMemes] = useState([]);
  const [memesInfo, setMemesInfo] = useState({});
  const [approvedMemes, setApprovedMemes] = useState([]);
  const [error, setError] = useState(null);
  const [usageData, setUsageData] = useState({});

  const changeData = setOnChange(settings, setSettings);

  useEffect(() => {
    if (generate) {
      const { instructions } = generate;
      setSettings({ ...settings, instructions });
    }
  }, [generate]);

  const { breadCrumbs } = props;

  if (generate) {
    breadCrumbs.push({
      title: generate.name,
      url,
      actions: [
        <Link to={generateForm(generate)}>
          <i className="fa fa-edit" />
        </Link>,
      ],
    });
  }

  const changeMemesDataHandler = useCallback(
    (index) => (evt) => {
      const _memes = [].concat(memes);
      const { name, value } = evt.target;
      _memes[index][name] = value;
      setMemes(_memes);
    },
    [memes]
  );

  const checkAllHandler = useCallback(() => {
    if (approvedMemes.length === memes.length) {
      setApprovedMemes([]);
    } else {
      setApprovedMemes(_.range(0, memes.length));
    }
  }, [approvedMemes, memes]);

  const approveCheckboxHandler = useCallback(
    (index) => () => {
      if (approvedMemes.includes(index)) {
        setApprovedMemes(approvedMemes.filter((value) => value != index));
      } else {
        setApprovedMemes(approvedMemes.concat([index]));
      }
    },
    [approvedMemes]
  );

  const saveChangesHandler = useCallback(() => {
    const { instructions } = settings;
    props.saveValue(
      { params, body: { instructions } },
      {
        onRequest() {
          setDataSaving(true);
        },
        onSuccess(data) {
          setDataSaving(false);
          setGenerate(data);
        },
      }
    );
  }, [settings, params]);

  useEffect(() => {
    const onSuccess = (body) => {
      setGenerate(body);
    };
    const onFailure = ({ status }) => {
      setNotFound(404 === status);
    };

    props.getValue({ params }, { onSuccess, onFailure });
  }, []);

  const confirmMemesHandler = useCallback(() => {
    const confirm = window.confirm(`Сохранить выбранные мемы (${approvedMemes.length})?`);
    if (confirm) {
      // составим список для отправления
      const data = approvedMemes
        .map((index) => memes[index])
        .map((meme) => {
          // учтем memeId из собственной структуры или из настроек (подразумеваем, что второй случай - это когда группировка идет по мемам)
          const memeId = meme.memeId || settings.memeId[0];
          return { ...meme, memeId };
        });
      props.saveMemes(
        { body: { data, settings }, params },
        {
          onRequest() {
            setMemesSaving(true);
          },
          onSuccess() {
            setMemesSaving(false);
            // удалим мемы, которые были сохранены
            setMemes(memes.filter((meme, index) => !approvedMemes.includes(index)));
            setApprovedMemes([]); // очистим список одобренных мемов
          },
          onFailure() {},
        }
      );
    }
  }, [approvedMemes, memes, generate, settings]);

  const runHandler = useCallback(() => {
    props.getMemes(
      { query: { _id: _.join(settings.memeId, ",") } },
      {
        onSuccess(result) {
          setMemesInfo(result);
        },
        onRequest() {
          setMemesInfo({});
        },
      }
    );

    props.runCompletion(
      { params, body: { ...settings } },
      {
        onRequest() {
          setMemesLoading(true);
          setMemes([]);
          setUsageData({});
          setApprovedMemes([]);
          setError(null);
        },
        onSuccess({ resultValues, usage }) {
          setMemesLoading(false);
          setUsageData(usage);
          setMemes(resultValues);
        },
        onFailure(result) {
          setMemesLoading(false);
          setError(result);
        },
      }
    );
  }, [settings, generate]);

  useEffect(() => {
    setDisabled(!(settings.memeId && settings.aspectCategoryId) || memeLoading);
  }, [settings, memeLoading]);

  const [lightboxUrl, setLightboxUrl] = useState(null);

  const lightBoxHandler = (url) => () => {
    setLightboxUrl(url);
  };

  return (
    <>
      {notFound && <Error400 />}

      {generate && (
        <Switch>
          {/* <Route exact path={path} render={() => <Redirect to={`${path}/form`} />} /> */}

          <Route exact path={`${path}`}>
            <div>
              <PageTitle {...breadCrumbs} />

              <div className="form-group">
                <textarea
                  type="text"
                  rows={10}
                  className="form-control"
                  name={"instructions"}
                  value={settings.instructions || ""}
                  onChange={changeData}
                />
              </div>

              <div className="form-group row">
                <div className="col-7">
                  <div className="row">
                    <div className="col-6">
                      <FloatMemes
                        intro={generate.dataGroup === "memes" ? "Мемы" : "Мем"}
                        _id={settings.memeId}
                        multi={generate.dataGroup === "memes"}
                        showTitle={generate.dataGroup !== "memes"}
                        showCount={generate.dataGroup === "memes"}
                        successHandler={(memeId) => {
                          setSettings({ ...settings, memeId });
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <FloatAspectsCategories
                        intro={settings.aspectCategoryId ? null : "Категория"}
                        _id={settings.aspectCategoryId}
                        multi={false}
                        showTitle={true}
                        showCount={false}
                        successHandler={([aspectCategoryId]) => {
                          setSettings({ ...settings, aspectCategoryId });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <button className="btn btn-primary" onClick={runHandler} disabled={disabled}>
                        {memeLoading ? (
                          <LoadingIcon className="mr-2" />
                        ) : (
                          <i className="fa fa-cogs mr-2" />
                        )}
                        <IntlMessages id="Special.MemeGenerates.runMemes" />
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-secondary"
                        onClick={saveChangesHandler}
                        disabled={settings.instructions === generate.instructions}
                      >
                        {dataSaving ? (
                          <LoadingIcon className="mr-2" />
                        ) : (
                          <i className="fa fa-save mr-2" />
                        )}
                        <IntlMessages id="save" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {memes.length ? (
                  <div>
                    {lightboxUrl ? (
                      <Lightbox
                        mainSrc={lightboxUrl}
                        onCloseRequest={lightBoxHandler(null)}
                        reactModalStyle={{ overlay: { zIndex: 1099 } }}
                      />
                    ) : null}

                    <Table responsive>
                      <thead>
                        <tr>
                          <th onClick={checkAllHandler}>
                            <input
                              className="form-control"
                              type="checkbox"
                              checked={approvedMemes.length === memes.length}
                              onChange={checkAllHandler}
                            />
                          </th>
                          <th style={{ width: "10%" }}>{generate.dataGroup}</th>
                          <th style={{ width: "20%" }}>textUp</th>
                          <th style={{ width: "20%" }}>textDown</th>
                          <th style={{ width: "40%" }}>forecast</th>
                          <th>
                            <MakeMemesImagesButton {...{ memes, setMemes, settings }} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(memes, (meme, index) => {
                          return (
                            <tr key={index}>
                              <td onClick={approveCheckboxHandler(index)}>
                                <input
                                  className="form-control"
                                  type="checkbox"
                                  checked={approvedMemes.includes(index)}
                                  onChange={approveCheckboxHandler(index)}
                                />
                              </td>

                              <td>
                                {generate.dataGroup === "memes" ? (
                                  <FloatMemes
                                    intro={meme.memeId ? null : "Мем"}
                                    _id={meme.memeId}
                                    multi={false}
                                    showTitle={true}
                                    showCount={false}
                                  />
                                ) : null}

                                {generate.dataGroup === "aspects" ? (
                                  <FloatAspectsValues
                                    intro={meme.aspectValueId ? null : "Аспект"}
                                    _id={meme.aspectValueId}
                                    multi={false}
                                    showTitle={true}
                                    showCount={false}
                                  />
                                ) : null}
                              </td>

                              <td>
                                <textarea
                                  rows={2}
                                  className="form-control form-control-sm"
                                  value={meme.textUp}
                                  name="textUp"
                                  onChange={changeMemesDataHandler(index)}
                                />
                              </td>
                              <td>
                                <textarea
                                  rows={2}
                                  className="form-control form-control-sm"
                                  value={meme.textDown}
                                  name="textDown"
                                  onChange={changeMemesDataHandler(index)}
                                />
                              </td>
                              <td>
                                <textarea
                                  rows={6}
                                  className="form-control form-control-sm"
                                  name="forecast"
                                  value={meme.forecast}
                                  onChange={changeMemesDataHandler(index)}
                                />
                              </td>
                              <td>
                                {/* 
                                <MakeMemeButton
                                  {...{ meme }}
                                  memeId={meme.memeId || settings.memeId[0]}
                                />
                                */}
                                {meme.imageUrl ? (
                                  <img
                                    src={meme.imageUrl}
                                    height={"150px"}
                                    onClick={lightBoxHandler(meme.imageUrl)}
                                  />
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td onClick={checkAllHandler}>
                            <input
                              className="form-control"
                              type="checkbox"
                              checked={approvedMemes.length === memes.length}
                              onChange={checkAllHandler}
                            />
                          </td>

                          <td colSpan={4}>
                            <button
                              className="btn btn-success"
                              disabled={!approvedMemes.length || memeSaving}
                              onClick={confirmMemesHandler}
                            >
                              {memeSaving ? <LoadingIcon className="mr-2" /> : null}
                              <IntlMessages id="Special.MemeGenerates.saveMemes" />
                            </button>
                          </td>
                          <td>
                            <MakeMemesImagesButton {...{ memes, setMemes, settings }} />
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                    {memesInfo.total > 0 ? (
                      <Table responsive className="my-2">
                        <thead>
                          <tr>
                            <th>memeName</th>
                            <th>memeDescription</th>
                            <th>memeUp</th>
                            <th>memeDown</th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.map(memesInfo.data, (meme) => {
                            return (
                              <tr key={meme._id}>
                                <td>{meme.name}</td>
                                <td>{meme.description}</td>
                                <td>{meme.textUp}</td>
                                <td>{meme.textDown}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    ) : null}

                    <div className="row mt-3">
                      <div className="col-1">
                        <b>Токены</b>
                      </div>
                      <div className="col">Промпт: {usageData.promptTokens}</div>
                      <div className="col">Осмысление: {usageData.reasonTokens}</div>
                      <div className="col">
                        Результат: {usageData.resultTokens - usageData.reasonTokens}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Route>

          <Route exact path={`${path}/form`}>
            <Form {...{ breadCrumbs, generate, setGenerate }} />
          </Route>

          <Route component={Error400} />
        </Switch>
      )}
    </>
  );
};

const previewMeme = api.post("/api/meme");
const MakeMemeButton = (props) => {
  const dispatch = useDispatch();
  const { meme, memeId } = props;
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [lightboxUrl, setLightboxUrl] = useState(null);

  const clickHandler = useCallback(() => {
    //

    const { textUp, textDown } = meme;
    const body = { memeId, textUp, textDown };

    dispatch(
      previewMeme(
        { body },
        {
          onRequest() {
            setLoading(true);
            setImageUrl(null);
          },
          onSuccess({ url }) {
            setLoading(false);
            setImageUrl(url);
          },
        }
      )
    );
  }, [meme, memeId]);

  const lightBoxHandler = (url) => () => {
    setLightboxUrl(url);
  };

  return (
    <div>
      {lightboxUrl ? (
        <Lightbox
          mainSrc={lightboxUrl}
          onCloseRequest={lightBoxHandler(null)}
          reactModalStyle={{ overlay: { zIndex: 1099 } }}
        />
      ) : null}

      <div>
        <button className="btn btn-secondary" onClick={clickHandler}>
          preview
        </button>
      </div>
      <div className="my-1">{loading ? <LoadingIcon /> : null}</div>
      <div>
        {imageUrl ? (
          <img src={imageUrl} height={"150px"} onClick={lightBoxHandler(imageUrl)} />
        ) : null}
      </div>
    </div>
  );
};

const MakeMemesImagesButton = (props) => {
  const dispatch = useDispatch();
  const { memes, setMemes, settings } = props;
  const [loading, setLoading] = useState(false);

  const makeImage = useCallback(
    (memes, index) => {
      const meme = memes[index];
      const { textUp, textDown } = meme;
      const memeId = meme.memeId || settings.memeId[0];
      const body = { memeId, textUp, textDown };

      dispatch(
        previewMeme(
          { body },
          {
            onRequest() {},
            onSuccess({ url }) {
              meme.imageUrl = url;
              memes[index] = meme;
              if (index < memes.length - 1) {
                makeImage(memes, index + 1);
              } else {
                setMemes(memes);
                setLoading(false);
              }
            },
          }
        )
      );
    },
    [memes, settings]
  );

  const clickHandler = useCallback(() => {
    //
    setLoading(true);
    makeImage(memes, 0);
  }, [memes]);

  return (
    <div>
      <div>
        <button className="btn btn-secondary text-nowrap" onClick={clickHandler} disabled={loading}>
          {loading ? <LoadingIcon className="mr-2" /> : null}
          preview
        </button>
      </div>
    </div>
  );
};

export default connect(null, {
  getValue: api.get(apiUrls.generateInfo),
  saveValue: api.patch(apiUrls.generateInfo),
  runCompletion: api.post(apiUrls.generateMemes),
  saveMemes: api.post(apiUrls.saveMemes),
  getMemes: api.get(apiUrls.getMemes),
})(GenerateInfo);
