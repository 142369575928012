import _ from "lodash";
import React, { useState } from "react";
import { loginBack, adminLogo } from "helper/constant";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Alert } from "reactstrap";
import { withRouter, Redirect } from "react-router-dom";
import { push } from "react-router-redux";
import enhancer from "./enhancer/LoginFormEnhancer";
import IntlMessages from "util/intlMessages";
import adminAPI, { accessToken } from "redux/api/admin";

const { auth, session } = adminAPI.actions;

const Login = (props) => {
  const dispatch = useDispatch();

  const [authError, setAuthError] = useState(false);
  const handleLogin = (e) => {
    e.preventDefault();
    let { values, handleSubmit } = props;

    if (values.login !== "" && values.password !== "") {
      const onSuccess = (body) => {
        const { accessToken: token = "", type = "" } = { ...body };
        if (token) {
          const onSuccess = () => {
            // window.cloclo_routes = body.routes;
            dispatch(push("/"));
          };

          localStorage.setItem(accessToken, `${type} ${token}`);
          dispatch(session.actions.getUser({}, { onSuccess }));
        }
      };
      const onFailure = ({ message }) => {
        setAuthError(message);
      };
      const onRequest = () => {
        setAuthError(null);
      };

      props.login({ body: values }, { onSuccess, onFailure });
    }
    handleSubmit();
  };

  const { values, handleChange, handleBlur, errors, touched, submitCount } = props;

  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={adminLogo} alt="icon" height="100px" />
        </div>

        <div className="login-title p-1">
          <IntlMessages id="Auth" />
        </div>
        <form className="p-3" onSubmit={handleLogin}>
          {authError && (
            <Alert className="alert-danger my-2" toggle={() => setAuthError(null)}>
              <IntlMessages id={authError} />
            </Alert>
          )}
          <div className="form-group">
            <label>
              <IntlMessages id="Auth.login" />
            </label>
            <input
              type="text"
              name="login"
              className="form-control react-form-input"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className="form-group">
            <label>
              <IntlMessages id="Auth.password" />
            </label>
            <input
              type="password"
              name="password"
              className="form-control react-form-input"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <button type="submit" className="btn form-button">
            <IntlMessages id="Auth.doLogin" />
          </button>

          <div
            className="text-center link-label"
            onClick={() => props.history.push("/forgotPassword")}
          >
            <IntlMessages id="Auth.forgotPassword" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default compose(withRouter, enhancer, connect(null, { ...auth.actions }))(Login);
