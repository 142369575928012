import apiClass from "./init";
import { accessToken } from "./index";

const session = new apiClass();

session.actions = {
  getUser: session.get("/api", {
    success: (state, result) => {
      return { ...result, authInProgress: false };
    },
    request: (state) => {
      return { ...state, authInProgress: true };
    },
  }),
  refreshInfo: session.get("/api", {
    success: (state, result) => {
      return { ...result };
    },
  }),
  logout: session.get("/", {
    success: () => {
      localStorage.removeItem(accessToken);
      return { user: null };
    },
  }),
};

export default session;
