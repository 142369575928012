/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import moment from "moment";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { FormButton, CheckTemplatesButton } from "./Form";
import { useIntl } from "react-intl";
import { Pager, FilterButton, Finder, Sorter, TableLoading } from "components/standart";
import Lightbox from "react-image-lightbox";
import { FloatTags } from "components/special/contents";
import { history } from "redux/store";
import { limit } from "variables";

import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const ContentTable = (props) => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [contentData, setContentData] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [lightboxUrl, setLightboxUrl] = useState(null);

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  const { contentInfo, contentForm } = props;

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setContentData(body);
    };
    const onRequest = () => {
      setContentData({ total: -1 });
    };

    query.limit && props.getContent({ query }, { onSuccess, onRequest });
  }, [query]);

  const lightBoxHandler = (url) => () => {
    setLightboxUrl(url);
  };

  return (
    <>
      {lightboxUrl ? (
        <Lightbox
          mainSrc={lightboxUrl}
          onCloseRequest={lightBoxHandler(null)}
          reactModalStyle={{ overlay: { zIndex: 1099 } }}
        />
      ) : null}

      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="Memes.name" />
            </th>

            <th>
              <IntlMessages id="Memes.templateId" />
            </th>
            <th>
              <IntlMessages id="Memes.exampleImageUrl" />
            </th>
            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>
            <th className="text-nowrap">
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
              <span className="mx-1" />
              <CheckTemplatesButton />
            </th>
          </tr>
        </thead>
        {contentData.total < 0 && <TableLoading />}
        <tbody>
          {_.map(contentData.data, (content, key) => (
            <tr key={key} className={`${!content.enabled ? "text-strike" : ""}`}>
              <td>{content.name}</td>
              <td>{content.templateId}</td>
              <td>
                {content.exampleImageUrl ? (
                  <img
                    alt={content.name}
                    src={content.exampleImageUrl}
                    height="80"
                    onClick={lightBoxHandler(content.exampleImageUrl)}
                  />
                ) : null}
              </td>
              <td>{moment(content.createdAt).format(dateTimeFormat)}</td>
              <td>
                <FormButton {...{ content, contentForm }} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={contentData.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getContent: api.get(apiUrls.memes),
})(ContentTable);
