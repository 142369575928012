import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";

import { SocialBlockAction } from "components/special/forms";
import { connect } from "react-redux";
import { adminAPI } from "redux/api";

import SettingsForm from "./Form";

const SettingsPremiums = (props) => {
  // аттрибуты, которые необходимо отобразить
  const attrs = [];

  // аттрибуты, которые необходимо отобразить
  const localesAttrs = [
    { attr: "giftScreenButton" },
    { attr: "giftStoryTitle" },
    { attr: "giftScreenTitle" },
    { attr: "giftScreenText", multiLine: true },
    { attr: "premiumLockButtonText" },
    { attr: "premiumButtonText" },
    { attr: "premiumSuccessButton" },
    { attr: "premiumSuccessTitle" },
    { attr: "premiumSuccessText", multiLine: true },
  ];
  const booleanAttrs = [];
  const htmlAttrs = [];
  const filesAttrs = [];

  const componentAttrs = [
    // { name: "adContacts", component: ContactsData },
    { name: "giftAction", component: SocialBlockAction },
  ];

  return (
    <SettingsForm
      {...{ componentAttrs, filesAttrs, htmlAttrs, booleanAttrs, localesAttrs, attrs }}
    />
  );
};

export default connect(null, {})(SettingsPremiums);
