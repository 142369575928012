/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { PageTitle } from "components/standart";
import { LocalesValues, Uploader, ImagePreview } from "components/standart";
import { SketchPicker } from "react-color";

import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { Alert } from "reactstrap";

import adminAPI from "redux/api/admin";
import { SwitchValue } from "components/special/forms";

import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const ContentForm = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs, content, setContent } = props;
  const params = useParams();
  const [dataSaved, setDataSaved] = useState(false);

  const changeContent = setOnChange(content, setContent);
  breadCrumbs.push({
    title: <IntlMessages id="Contents.Form" />,
    url,
  });

  const successAlertRef = useRef(null);

  const saveHandler = () => {
    const onSuccess = (body) => {
      setContent(body);
      setDataSaved(true);
      successAlertRef.current.scrollIntoView();
    };

    const onRequest = () => {
      setDataSaved(false);
    };

    const onFailure = () => {
      setDataSaved(false);
    };

    props.saveContent({ params, body: content }, { onSuccess, onFailure, onRequest });
  };

  const uploadLogoFilesHandler = ([file]) => {
    const fileId = file._id;
    setContent({ ...content, fileId });
  };

  return (
    <div>
      <PageTitle {...breadCrumbs} />

      <div className="row">
        <div className="col-12">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                <Alert
                  className="alert-success"
                  isOpen={dataSaved}
                  toggle={() => setDataSaved(false)}
                >
                  <IntlMessages id="saved" />
                </Alert>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Contents.name" />
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    name="name"
                    value={content.name || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Marketplaces.url" />
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    name="url"
                    value={content.url || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Marketplaces.bgColor" />
                </label>
                <div className="col-8">
                  <SketchPicker
                    color={content.bgColor || ""}
                    onChange={({ hex }) => setContent({ ...content, bgColor: hex })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Marketplaces.fontColor" />
                </label>
                <div className="col-8">
                  <SketchPicker
                    color={content.fontColor || ""}
                    onChange={({ hex }) => setContent({ ...content, fontColor: hex })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="localesName" />
                  <LocalesValues.AddButton
                    className="btn-sm float-right"
                    values={content.localesName}
                    changeValues={(localesName) => setContent({ ...content, localesName })}
                  />
                </label>
                <div className="col-8">
                  <LocalesValues
                    values={content.localesName}
                    changeValues={(localesName) => setContent({ ...content, localesName })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Marketplaces.logo" />

                  <Uploader
                    className="btn-success btn-sm pull-right"
                    successHandler={uploadLogoFilesHandler}
                    imagesOnly="true"
                    multiple={false}
                  />
                </label>
                <div className="col-8">
                  {content?.fileId && (
                    <ImagePreview
                      file={{ _id: content.fileId }}
                      deleteHandler={() => {
                        setContent({ ...content, fileId: null });
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="tables.enabled" />
                </label>
                <div className="col-8 my-auto">
                  <SwitchValue value={content.enabled} onChange={changeContent} />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-4 col-auto">
                  <button className="form-control c-primary" type="button" onClick={saveHandler}>
                    <IntlMessages id="save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButton = (props) => {
  const { contentForm, content } = props;
  return (
    <Link to={contentForm(content)} className="btn btn-secondary btn-sm">
      <i className="fa fa-edit" />
    </Link>
  );
};

export default connect(null, {
  saveContent: api.patch(apiUrls.marketplacesInfo),
})(ContentForm);
