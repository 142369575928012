import { path as parentPath } from "../init";

export const path = "/meme_generates";

export const apiUrls = {
  getMemes: "/api/contents/memes",
  generates: "/api/special/meme_generates",
  generatesEnv: "/api/special/meme_generates/env",
  generateInfo: "/api/special/meme_generates/generate_{generateId}",
  generateMemes: "/api/special/meme_generates/generate_{generateId}/run_completion",
  saveMemes: "/api/special/meme_generates/generate_{generateId}/saveMemes",
};

export const generateInfo = (generate) => {
  return `${parentPath}${path}/generate_${generate._id}`;
};

export const generateForm = (generate) => {
  return `${generateInfo(generate)}/form`;
};
