import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { setOnChange } from "components/special";

import adminAPI from "redux/api/admin";
import { FloatLocales } from "components/special/contents";

import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const AddFaq = (props) => {
  const { faqForm, localeId } = props;
  const [openModal, setOpenModal] = useState(false);
  const [newFaq, setNewFaq] = useState({});

  const changeNewFaq = setOnChange(newFaq, setNewFaq);
  const toggleModal = () => setOpenModal(!openModal);
  const dispatch = useDispatch();

  const addHandler = () => {
    const onSuccess = (body) => {
      dispatch(push(faqForm(body)));
    };
    props.addFaq({ query: { localeId }, body: newFaq }, { onSuccess });
  };

  useEffect(() => {
    setNewFaq({ localeId });
  }, [openModal]);

  return (
    <>
      <i className="fa fa-plus-square" onClick={toggleModal} />

      {openModal && (
        <Modal isOpen={openModal} centered className="modal-sm" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="Www.Faq.Add" />
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <IntlMessages id="Www.Faq.title">
                {(title) => (
                  <input
                    type="text"
                    value={newFaq.title || ""}
                    name="title"
                    onChange={changeNewFaq}
                    required
                    className="form-control"
                    placeholder={title}
                    autoComplete="off"
                  />
                )}
              </IntlMessages>
            </div>

            <div className="form-group row">
              <div className="col">
                <IntlMessages id="Locales" />
              </div>
              <div className="col">
                <FloatLocales
                  _id={newFaq.localeId}
                  showTitle={true}
                  showCount={false}
                  multi={false}
                  successHandler={([localeId]) => {
                    setNewFaq({ ...newFaq, localeId });
                  }}
                />
              </div>

              {/* <LocalesSelector value={newFaq.locale} setValue={changeNewFaq} /> */}
            </div>

            <div className="form-group">
              <button
                type="button"
                isDisabled={!newFaq.title && !newFaq.localeId}
                className="form-control c-primary"
                onClick={addHandler}
              >
                <IntlMessages id="save" />
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addFaq: api.post(apiUrls.faq),
})(AddFaq);
