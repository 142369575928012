import { path as itemsPath } from "../init";

export const path = "/types";

export const apiUrls = {
  types: "/api/items/types",
  typesPositions: "/api/items/types/positions",
  typesInfo: "/api/items/types/type_{typeId}",
};

export const typesInfo = (type) => {
  return `${itemsPath}${path}/type_${type._id}`;
};

export const typesForm = (type) => {
  return `${typesInfo(type)}/form`;
};
