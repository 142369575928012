/** модальное окно для выбора стран в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import adminAPI from "redux/api/admin";
import { FloatModal } from "components/special";
import AddContent from "views/admin/Contents/Tags/Add";
const { api } = adminAPI.actions;

const FloatTagsModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Tags" />}
      {...props}
      head={(searchTags, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="Tags.name" />
            </th>
            <th style={{ width: "30px" }}>
              <AddContent successHandler={() => setSearchQuery({})} />
            </th>
          </>
        );
      }}
      row={(tag, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!tag.enabled ? "text-strike" : ""}>
            {tag.name}
          </td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatTags = (props) => {
  return <FloatTagsModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/contents/tags"),
})(FloatTags);
