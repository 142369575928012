/** управление данными для сайта
 */
import _ from "lodash";
import React, { useEffect } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";

import Celebrities from "./Celebrities";
import DailyQuotes from "./DailyQuotes";
import DailyQuotesCategories from "./DailyQuotesCategories";
import DailyQuotesTopics from "./DailyQuotesTopics";
import AstroSigns from "./AstroSigns";
import AstroOverlaps from "./AstroOverlaps";
import AstroWeeks from "./AstroWeeks";
import Preferences from "./Preferences";
import OverlapsGradients from "./OverlapsGradients";
import OverlapsGradientsTexts from "./OverlapsGradientsTexts";
import Countries from "./Countries";
import Locales from "./Locales";
import Marketplaces from "./Marketplaces";
import Premiums from "./Premiums";
import { BreadCrumbs } from "components/standart/PageTitle";
import AspectsCategories from "./AspectsCategories";
import AspectsQuotes from "./AspectsQuotes";
import AspectsValues from "./AspectsValues";
import Memes from "./Memes";
import AspectsCompatibilities from "./AspectsCompatibilities";
// import Tags from "./Tags";

const path = "/contents";

const Contents = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs = new BreadCrumbs() } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Contents" />,
    url,
  });
  return (
    <div className="react-strap-doc">
      <Switch>
        <Route path={`${path}/aspectsCategories`}>
          <AspectsCategories {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/aspectsQuotes`}>
          <AspectsQuotes {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/aspectsCompatibilities`}>
          <AspectsCompatibilities {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/aspectsValues`}>
          <AspectsValues {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/memes`}>
          <Memes {...{ breadCrumbs }} />
        </Route>

        {/* 
        <Route path={`${path}/tags`}>
          <Tags {...{ breadCrumbs }} />
        </Route>
        */}

        <Route path={`${path}/dailyQuotes`}>
          <DailyQuotes {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/dailyQuotesCategories`}>
          <DailyQuotesCategories {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/dailyQuotesTopics`}>
          <DailyQuotesTopics {...{ breadCrumbs }} />
        </Route>

        {/* 
        <Route path={`${path}/astro_overlaps`}>
          <Overlaps {...{ breadCrumbs }} />
        </Route>
        */}

        <Route path={`${path}/astroSigns`}>
          <AstroSigns {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/celebrities`}>
          <Celebrities {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/astroOverlaps`}>
          <AstroOverlaps {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/astroWeeks`}>
          <AstroWeeks {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/preferences`}>
          <Preferences {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/premiums`}>
          <Premiums {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/gradients`}>
          <OverlapsGradients {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/gradientsTexts`}>
          <OverlapsGradientsTexts {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/countries`}>
          <Countries {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/locales`}>
          <Locales {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/marketplaces`}>
          <Marketplaces {...{ breadCrumbs }} />
        </Route>

        <Route exact path={path} render={() => <Redirect to={`${path}/dailyQuotes`} />} />
        <Route component={Error400} />
      </Switch>
    </div>
  );
};

export default { path, component: Contents };
