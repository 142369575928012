import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, Link, Redirect, useParams, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import PageTitle from "components/standart/PageTitle";

import adminAPI from "redux/api/admin";
import { apiUrls, emitsForm } from "./init";
import ItemsTable from "../Table";
const { api } = adminAPI.actions;

const EmitInfo = (props) => {
  const { url, path } = useRouteMatch();

  const params = useParams();

  const [emit, setEmit] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const { breadCrumbs } = props;

  if (emit) {
    breadCrumbs.push({
      title: emit.ident,
      url,
      actions: [],
    });
  }

  useEffect(() => {
    const onSuccess = (body) => {
      setEmit(body);
    };
    const onFailure = ({ status }) => {
      setNotFound(404 === status);
    };

    props.getEmit({ params }, { onSuccess, onFailure });
  }, []);

  return (
    <>
      {notFound && <Error400 />}

      {emit && (
        <Switch>
          {/* <Route exact path={path} render={() => <Redirect to={`${url}/form`} />} /> */}
          <Route exact path={`${path}`}>
            <div>
              <PageTitle {...breadCrumbs} />

              {/* <div>{JSON.stringify(emit)}</div> */}
              <ItemsTable query={{ emitId: params.emitId }} />
            </div>
          </Route>

          <Route component={Error400} />
        </Switch>
      )}
    </>
  );
};

export default connect(null, {
  getEmit: api.get(apiUrls.emitInfo),
})(EmitInfo);
