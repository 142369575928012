/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { PageTitle, MarketplacesUrl } from "components/standart";
import { LocalesValues, CurrenciesValues, Uploader, ImagePreview } from "components/standart";

import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { Alert } from "reactstrap";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import adminAPI from "redux/api/admin";
import { SwitchValue } from "components/special/forms";

import { apiUrls, categoriesForm } from "./init";
const { api } = adminAPI.actions;

const CategoryForm = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs, category, setCategory } = props;
  const params = useParams();
  const [dataSaved, setDataSaved] = useState(false);
  const [error, setError] = useState(false);

  const changeContent = setOnChange(category, setCategory);
  breadCrumbs.push({
    title: <IntlMessages id="Www.Contents.Form" />,
    url,
  });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return _.filter(result, _.size);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const filesId = reorder(category.filesId, result.source.index, result.destination.index);
    setCategory({ ...category, filesId });
  };

  const successAlertRef = useRef(null);

  const saveHandler = () => {
    const onSuccess = (body) => {
      setCategory(body);
      setDataSaved(true);
      successAlertRef.current.scrollIntoView();
    };

    const onRequest = () => {
      setDataSaved(false);
      setError(false);
    };

    const onFailure = ({ message }) => {
      setDataSaved(false);
      setError(message);
    };

    props.saveCategory({ params, body: category }, { onSuccess, onFailure, onRequest });
  };

  const uploadAvatarFilesHandler = ([file]) => {
    const avatarFileId = file._id;
    setCategory({ ...category, avatarFileId });
  };

  const uploadFilesHandler = (files) => {
    const { filesId } = category;
    _.map(files, ({ _id }) => filesId.push(_id));

    setCategory({ ...category, filesId });
  };
  return (
    <div>
      <PageTitle {...breadCrumbs} />

      <div className="row">
        <div className="col-12">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                <Alert
                  className="alert-success"
                  isOpen={dataSaved}
                  toggle={() => setDataSaved(false)}
                >
                  <IntlMessages id="saved" />
                </Alert>
              </div>

              {error && (
                <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
                  <IntlMessages id={error} />
                </Alert>
              )}

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.name" />
                </label>
                <div className="col-8">
                  <input
                    name="name"
                    value={category.name || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="localesName" />

                  <LocalesValues.AddButton
                    className="btn-sm float-right"
                    values={category.localesName}
                    changeValues={(localesName) => setCategory({ ...category, localesName })}
                  />
                </label>
                <div className="col">
                  <LocalesValues
                    values={category.localesName}
                    changeValues={(localesName) => setCategory({ ...category, localesName })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.alias" />
                </label>
                <div className="col-8">
                  <input
                    name="alias"
                    value={category.alias || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.price" />

                  <CurrenciesValues.AddButton
                    className="btn-sm float-right"
                    values={category.prices}
                    changeValues={(prices) => setCategory({ ...category, prices })}
                  />
                </label>
                <div className="col">
                  <CurrenciesValues
                    values={category.prices}
                    changeValues={(prices) => setCategory({ ...category, prices })}
                  />
                </div>
              </div>
              {/* 
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.oldPrice" />

                  <CurrenciesValues.AddButton
                    className="btn-sm float-right"
                    values={category.oldPrices}
                    changeValues={(oldPrices) => setCategory({ ...category, oldPrices })}
                  />
                </label>
                <div className="col">
                  <CurrenciesValues
                    values={category.oldPrices}
                    changeValues={(oldPrices) => setCategory({ ...category, oldPrices })}
                  />
                </div>
              </div>
               */}
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.urls" />

                  <MarketplacesUrl.AddButton
                    className="btn-sm float-right"
                    values={category.urls}
                    changeValues={(urls) => setCategory({ ...category, urls })}
                  />
                </label>
                <div className="col">
                  <MarketplacesUrl
                    values={category.urls}
                    changeValues={(urls) => setCategory({ ...category, urls })}
                  />
                </div>
              </div>
              {/* 

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.price" />
                </label>
                <div className="col-8">
                  <input
                    name="price"
                    value={category.price || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
             */}

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.avatar" />

                  <Uploader
                    className="btn-success btn-sm pull-right"
                    successHandler={uploadAvatarFilesHandler}
                    imagesOnly="true"
                    multiple={false}
                  />
                </label>
                <div className="col-8">
                  {category?.avatarFileId && (
                    <ImagePreview
                      file={{ _id: category.avatarFileId }}
                      deleteHandler={() => {
                        setCategory({ ...category, avatarFileId: null });
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.images" />

                  <Uploader
                    className="btn-success btn-sm pull-right"
                    successHandler={uploadFilesHandler}
                    imagesOnly="true"
                    multiple={true}
                  />
                </label>
                <div className="col-8">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className="-d-flex"
                          //style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          {_.map(category.filesId, (_id, index) => {
                            return (
                              <Draggable key={_id} draggableId={_id} index={index}>
                                {(provided, snapshot) => (
                                  <span
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <ImagePreview
                                      file={{ _id }}
                                      deleteHandler={() => {
                                        const filesId = _.filter(
                                          category.filesId,
                                          (fileId) => fileId !== _id
                                        );
                                        setCategory({ ...category, filesId });
                                      }}
                                    />
                                  </span>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.greetingHeader" />

                  <LocalesValues.AddButton
                    className="btn-sm float-right"
                    values={category.greetingHeader}
                    changeValues={(greetingHeader) => setCategory({ ...category, greetingHeader })}
                  />
                </label>
                <div className="col">
                  <LocalesValues
                    values={category.greetingHeader}
                    changeValues={(greetingHeader) => setCategory({ ...category, greetingHeader })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.greetingText" />

                  <LocalesValues.AddButton
                    className="btn-sm float-right"
                    values={category.greetingText}
                    changeValues={(greetingText) => setCategory({ ...category, greetingText })}
                  />
                </label>
                <div className="col">
                  <LocalesValues
                    values={category.greetingText}
                    isHtml={true}
                    changeValues={(greetingText) => setCategory({ ...category, greetingText })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.description" />

                  <LocalesValues.AddButton
                    className="btn-sm float-right"
                    values={category.description}
                    changeValues={(description) => setCategory({ ...category, description })}
                  />
                </label>
                <div className="col">
                  <LocalesValues
                    values={category.description}
                    isHtml={true}
                    changeValues={(description) => setCategory({ ...category, description })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.digitalOnly" />
                </label>
                <div className="col-8 my-auto">
                  <SwitchValue
                    value={category.digitalOnly}
                    name="digitalOnly"
                    onChange={changeContent}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Items.Categories.notInSale" />
                </label>
                <div className="col-8 my-auto">
                  <SwitchValue
                    value={category.notInSale}
                    name="notInSale"
                    onChange={changeContent}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="tables.enabled" />
                </label>
                <div className="col-8 my-auto">
                  <SwitchValue value={category.enabled} onChange={changeContent} />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-4 col-auto">
                  <button className="form-control c-primary" type="button" onClick={saveHandler}>
                    <IntlMessages id="save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButton = (props) => {
  const { category } = props;
  return (
    <Link to={categoriesForm(category)} className={`btn btn-secondary btn-sm ${props.className}`}>
      <i className="fa fa-edit" />
    </Link>
  );
};

export default connect(null, {
  saveCategory: api.patch(apiUrls.categoryInfo),
})(CategoryForm);
