/** модальное окно для выбора локализаций в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import adminAPI from "redux/api/admin";
import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatLocalesModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Locales" />}
      {...props}
      head={(search, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="name" />
            </th>
            <th>
              <IntlMessages id="Locales.code" />
            </th>
            <th>
              <IntlMessages id="Locales.icon" />
            </th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(locale, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!locale.enabled ? "text-strike" : ""}>
            {locale.name}
          </td>
          <td>{locale.code}</td>
          <td>{locale.icon}</td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatLocales = (props) => {
  return <FloatLocalesModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/contents/locales"),
})(FloatLocales);
