export const colors = [
  "#ffe670",
  "#00c486",
  "#0e6ae5",
  "#e53e0e",
  "#e5800e",
  "#670ee5",
  "#34ebe8",
  "#5d506e",
  "#a186c4",
  "#a6e35f",
  "#f50abe",
  "#9c4702",
  "#6e6e6e",
  "#000000",
];
