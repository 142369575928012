/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FloatLocales } from "components/special/contents";
import { setOnChange } from "components/special";
import { SwitchValue } from "components/special/forms";

import IntlMessages from "util/intlMessages";
import { adminAPI, setSuccess } from "redux/api";
import { apiUrls } from "./init";
import Select from "react-select";

const { api } = adminAPI.actions;

const GenerateData = (props) => {
  const { generate, setGenerate } = props;
  const [env, setEnv] = useState({});
  const [contents, setContents] = useState({});
  const [groups, setGroups] = useState({});
  const [gptModels, setGptModels] = useState({});
  const changeData = setOnChange(generate, setGenerate);

  useEffect(() => {
    setContents(
      _.map(env.contents, (value) => {
        return { value, label: value };
      })
    );
    setGptModels(
      _.map(env.gptModels, (value) => {
        return { value, label: value };
      })
    );
    setGroups(
      _.map(env.groups, (value) => {
        return { value, label: value };
      })
    );
  }, [env]);

  useEffect(() => {
    props.getEnv({}, setSuccess(setEnv));
  }, []);

  return (
    <>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Special.MemeGenerates.name" />
        </label>
        <div className="col-8 my-auto">
          <input
            type="text"
            className="form-control"
            name={"name"}
            value={generate.name || ""}
            onChange={changeData}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-4">
          <IntlMessages id="Special.MemeGenerates.gptModel" />
        </div>
        <div className="col">
          <Select
            isMulti={false}
            value={_.filter(gptModels, ({ value }) => value === generate.gptModel)}
            options={gptModels}
            placeholder={<IntlMessages id="Special.MemeGenerates.gptModel" />}
            onChange={({ value }) => setGenerate({ ...generate, gptModel: value })}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-4">
          <IntlMessages id="Special.MemeGenerates.dataGroup" />
        </div>
        <div className="col">
          <Select
            isMulti={false}
            value={_.filter(groups, ({ value }) => value === generate.dataGroup)}
            options={groups}
            placeholder={<IntlMessages id="Special.MemeGenerates.dataGroup" />}
            onChange={({ value }) => setGenerate({ ...generate, dataGroup: value })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Special.MemeGenerates.content" />
        </label>
        <div className="col">
          <Select
            isMulti={false}
            value={_.filter(contents, ({ value }) => value === generate.content)}
            options={contents}
            placeholder={<IntlMessages id="Special.MemeGenerates.content" />}
            onChange={({ value }) => setGenerate({ ...generate, content: value })}
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getEnv: api.get(apiUrls.generatesEnv),
})(GenerateData);
