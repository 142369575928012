import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, Link, Redirect, useParams, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import PageTitle from "components/standart/PageTitle";
import Form from "./Form";

import { adminAPI } from "redux/api";
import { apiUrls, typesForm } from "./init";
const { api } = adminAPI.actions;

const TypeInfo = (props) => {
  const { url, path } = useRouteMatch();

  const params = useParams();

  const [type, setType] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const { breadCrumbs } = props;

  if (type) {
    breadCrumbs.push({
      title: type.title,
      url,
      actions: [
        <Link to={typesForm(type)}>
          <i className="fa fa-edit" />
        </Link>,
      ],
    });
  }

  useEffect(() => {
    const onSuccess = (body) => {
      setType(body);
    };
    const onFailure = ({ status }) => {
      setNotFound(404 === status);
    };

    props.getType({ params }, { onSuccess, onFailure });
  }, []);

  return (
    <>
      {notFound && <Error400 />}

      {type && (
        <Switch>
          <Route exact path={path} render={() => <Redirect to={`${url}/form`} />} />
          {/* 
          <Route exact path={`${path}`}>
            <div>
              <PageTitle {...breadCrumbs} />

              <div>{content.data}</div>
            </div>
          </Route>
          */}

          <Route exact path={`${path}/form`}>
            <Form {...{ breadCrumbs, type, setType }} />
          </Route>

          <Route component={Error400} />
        </Switch>
      )}
    </>
  );
};

export default connect(null, {
  getType: api.get(apiUrls.typesInfo),
})(TypeInfo);
