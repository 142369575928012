import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import { FloatMarketplaces } from "components/special/contents";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { setOnChange } from "components/special";
import adminAPI from "redux/api/admin";

import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const AddFeedback = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [newFeedback, setNewFeedback] = useState({});
  const changeNewFeedback = setOnChange(newFeedback, setNewFeedback);
  const toggleModal = () => setOpenModal(!openModal);
  const dispatch = useDispatch();
  const { feedbackForm } = props;

  const addHandler = () => {
    const onSuccess = (body) => {
      dispatch(push(feedbackForm(body)));
    };
    props.addFeedback({ body: newFeedback }, { onSuccess });
  };

  return (
    <>
      <i className="fa fa-plus-square" onClick={toggleModal} />

      {openModal && (
        <Modal isOpen={openModal} centered size="md" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="Www.Contents.Add" />
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <IntlMessages id="Www.Feedbacks.value">
                {(title) => (
                  <input
                    type="number"
                    value={newFeedback.value || ""}
                    name="value"
                    onChange={changeNewFeedback}
                    required
                    className="form-control"
                    placeholder={title}
                    autoComplete="off"
                  />
                )}
              </IntlMessages>
            </div>

            <div className="form-group row">
              <div className="col">
                <IntlMessages id="Marketplaces" />
              </div>
              <div className="col">
                <FloatMarketplaces
                  _id={newFeedback.marketplaceId}
                  showTitle={true}
                  showCount={false}
                  multi={false}
                  successHandler={([marketplaceId]) => {
                    setNewFeedback({ ...newFeedback, marketplaceId });
                  }}
                />
              </div>
            </div>

            <div className="form-group">
              <button type="button" className="form-control c-primary" onClick={addHandler}>
                <IntlMessages id="save" />
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addFeedback: api.post(apiUrls.feedback),
})(AddFeedback);
