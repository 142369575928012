import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import createReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { apiMiddleware } from "redux-api-middleware";

import { middlewares as adminAPIMiddlewares } from "./api/admin";
import { middlewares as filesAPIMiddlewares } from "./api/files";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["scrumboard", "themeSetting", "LanguageSwitcher", "themeChanger"],
};

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const middlewares = [
  thunk,
  routeMiddleware,
  apiMiddleware,
  ...adminAPIMiddlewares,
  ...filesAPIMiddlewares,
];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// создание постоянного (Persist) store
// const persistedReducer = persistReducer(persistConfig, createReducer());
// const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(...middlewares)));
// создание не-постоянного (non-Persist) store
const store = createStore(createReducer(), composeEnhancer(applyMiddleware(...middlewares)));
const persistor = persistStore(store);

export { store, history, persistor };
