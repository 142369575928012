/** пользовательские настройки
 * оперирует констекстом api запросов к CloClo
 * в самом начале инициализирует текущие пользовательские настройки
 *
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PageTitle from "components/common/PageTitle";
import IntlMessages from "util/intlMessages";

// import ChangePassword from "views/admin/Settings/ChangePassword";
import PhotoUploader from "./PhotoUploader";
// import ChangeName from './ChangeName';
import LoginsTable from "./Logins/LoginsTable";

import adminAPI from "redux/api/admin";

const { settings } = adminAPI.actions;

const UserSettings = (props) => {
  const { settingsStore } = props;

  useEffect(() => {
    props.getSettings();
  }, []);

  return (
    <div className="react-strap-doc">
      <PageTitle id="settings" />

      <div className="row">
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">
              <IntlMessages id="settings.photo" />
            </div>
            <div className="roe-card-body">
              <PhotoUploader
                fileData={settingsStore}
                changePhotoHandler={props.changePhoto}
                deletePhotoHandler={props.deletePhoto}
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">
              <IntlMessages id="Logins" />
            </div>
            <div className="roe-card-body">
              <LoginsTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  settingsStore: state.api.settings,
});
export default connect(mapStateToProps, {
  ...settings.actions,
})(UserSettings);
