/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { FormButton } from "./Form";
import { useIntl } from "react-intl";
import { Pager, Sorter, TableLoading } from "components/standart";
import { history } from "redux/store";
import { limit } from "variables";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const ContentTable = (props) => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [contentData, setContentData] = useState({ total: 0 });
  const [query, setQuery] = useState({});

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  const { contentForm } = props;

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setContentData(body);
    };
    const onRequest = () => {
      setContentData({ total: -1 });
    };

    query.limit && props.getContent({ query: { ...query } }, { onSuccess, onRequest });
  }, [query]);

  return (
    <>
      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="name" />
            </th>
            <th>
              <IntlMessages id="symbol" />
            </th>
            <th>
              <IntlMessages id="Payments.Currencies.isoCode" />
            </th>
            <th>
              <IntlMessages id="Payments.Currencies.isoName" />
            </th>
            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>
            <th></th>
          </tr>
        </thead>
        {contentData.total < 0 && <TableLoading />}

        <tbody>
          {_.map(contentData.data, (content, index) => (
            <tr className={`${!content.enabled ? "text-strike" : ""}`} key={index}>
              <td>{content.name}</td>

              <td>{content.symbol}</td>
              <td>{content.isoCode}</td>
              <td>{content.isoName}</td>
              <td>{moment(content.createdAt).format(dateTimeFormat)}</td>

              <td>
                <FormButton {...{ content, contentForm }} />
              </td>
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={contentData.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getContent: api.get(apiUrls.paymentsCurrencies),
})(ContentTable);
