import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "react-router-redux";
import { Alert } from "reactstrap";
import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { setOnChange } from "components/special";
import { adminAPI } from "redux/api";
import { apiUrls, emitsInfo } from "./init";

const { api } = adminAPI.actions;

const AddEmit = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [newEmit, setNewEmit] = useState({});
  const changeNewEmit = setOnChange(newEmit, setNewEmit);
  const toggleModal = () => setOpenModal(!openModal);
  const dispatch = useDispatch();
  const { contentForm } = props;

  const addHandler = () => {
    const onSuccess = (body) => {
      dispatch(push(emitsInfo(body)));
    };
    props.addEmit(
      { body: newEmit },
      {
        onSuccess,
        onRequest() {
          setError(null);
        },
        onFailure({ message }) {
          setError(message);
        },
      }
    );
  };

  useEffect(() => {
    setNewEmit({});
  }, [openModal]);

  return (
    <>
      <i className="fa fa-plus-square" onClick={toggleModal} />

      {openModal && (
        <Modal isOpen={openModal} centered size="md" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="Items.Emits.Add" />
          </ModalHeader>
          <ModalBody>
            {error && (
              <Alert className="alert-danger" isOpen="true" toggle={() => setError(false)}>
                <IntlMessages id={error} />
              </Alert>
            )}

            <div className="form-group">
              <IntlMessages id="Items.Emits.quantity">
                {(title) => (
                  <input
                    type="number"
                    value={newEmit.quantity || ""}
                    name="quantity"
                    onChange={changeNewEmit}
                    required
                    className="form-control"
                    placeholder={title}
                    autoComplete="off"
                  />
                )}
              </IntlMessages>
            </div>

            <div className="form-group">
              <IntlMessages id="Items.Emits.comment">
                {(title) => (
                  <input
                    type="text"
                    value={newEmit.comment || ""}
                    name="comment"
                    onChange={changeNewEmit}
                    required
                    className="form-control"
                    placeholder={title}
                    autoComplete="off"
                  />
                )}
              </IntlMessages>
            </div>

            <div className="form-group">
              <button
                type="button"
                disabled={+newEmit?.quantity <= 0}
                className="form-control c-primary"
                onClick={addHandler}
              >
                <IntlMessages id="save" />
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addEmit: api.post(apiUrls.emits),
})(AddEmit);
