/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { PageTitle } from "components/standart";
import { MetaData } from "components/special/forms";
import { FloatLocales } from "components/special/contents";


import ReactQuill from "react-quill";
import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { Alert } from "reactstrap";

import adminAPI from "redux/api/admin";
import { SwitchValue } from "components/special/forms";
import { ContextSegments } from "components/standart";
import { apiUrls } from "../init";
const { api } = adminAPI.actions;

const ContentForm = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs, content, setContent } = props;
  const params = useParams();
  const [dataSaved, setDataSaved] = useState(false);

  const changeContent = setOnChange(content, setContent);
  breadCrumbs.push({
    title: <IntlMessages id="Www.Contents.Form" />,
    url,
  });

  const successAlertRef = useRef(null);

  const saveHandler = () => {
    const onSuccess = (body) => {
      setContent(body);
      setDataSaved(true);
      successAlertRef.current.scrollIntoView();
    };

    const onRequest = () => {
      setDataSaved(false);
    };

    const onFailure = () => {
      setDataSaved(false);
    };

    props.saveContent({ params, body: content }, { onSuccess, onFailure, onRequest });
  };

  return (
    <div>
      <PageTitle {...breadCrumbs} />

      <div className="row">
        <div className="col-12">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                <Alert
                  className="alert-success"
                  isOpen={dataSaved}
                  toggle={() => setDataSaved(false)}
                >
                  <IntlMessages id="saved" />
                </Alert>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Www.Contents.title" />
                </label>
                <div className="col-8">
                  <input
                    name="title"
                    value={content.title || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Www.Contents.url" />
                </label>
                <div className="col-8">
                  <input
                    name="url"
                    value={content.url || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="form-group row" style={{ height: 450 }}>
                <label className="col-4 col-form-label">
                  <IntlMessages id="Www.Contents.data" />
                </label>
                <div className="col-8">
                  <ReactQuill
                    value={content.data || ""}
                    style={{ height: 400 }}
                    onChange={(data) => {
                      setContent({ ...content, data });
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Locales" />
                </label>
                <div className="col-8">
                  <FloatLocales
                    _id={content.localeId}
                    showTitle={true}
                    showCount={false}
                    multi={false}
                    successHandler={([localeId]) => {
                      setContent({ ...content, localeId });
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="tables.enabled" />
                </label>
                <div className="col-8 my-auto">
                  <SwitchValue value={content.enabled} onChange={changeContent} />
                </div>
              </div>

              <MetaData data={content} setData={setContent} />

              <div className="form-group row">
                <div className="col-4 col-auto">
                  <button className="form-control c-primary" type="button" onClick={saveHandler}>
                    <IntlMessages id="save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButton = (props) => {
  const { contentForm, content } = props;
  return (
    <Link to={contentForm(content)} className="btn btn-secondary btn-sm">
      <i className="fa fa-edit" />
    </Link>
  );
};

export default connect(null, {
  saveContent: api.patch(apiUrls.contentInfo),
})(ContentForm);
