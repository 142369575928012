import { path as itemsPath } from "../init";

export const path = "/categories";

export const apiUrls = {
  categories: "/api/items/categories",
  categoryInfo: "/api/items/categories/category_{category_id}",
};

export const categoriesInfo = (category) => {
  return `${itemsPath}${path}/category_${category._id}`;
};

export const categoriesForm = (category) => {
  return `${categoriesInfo(category)}/form`;
};
