import _ from "lodash";
import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { Link, useParams, useRouteMatch, Route, Switch } from "react-router-dom";
import { Nav, NavLink, NavItem } from "reactstrap";
import IntlMessages from "util/intlMessages";

import Info from "./Info";
import ItemsTable from "../../Items/Table";

const CustomerData = (props) => {
  const { url, path } = useRouteMatch();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(0);

  const { customer } = props;
  const NavTabs = [
    {
      id: "Customers.Info",
      title: <IntlMessages id="Customers.Info" />,
      component: <Info {...customer} />,
    },
    {
      path: "/items",
      title: <IntlMessages id="Customers.Items" />,
      component: <ItemsTable />,
    },
  ];
  return (
    <>
      <Route path={path}>
        <div className="mb-1">
          <Nav tabs>
            {NavTabs.map((navTab, index) => {
              const link = url + _.toString(navTab.path);
              return (
                <Fragment key={index}>
                  <NavItem>
                    <span className={`nav-link ${currentTab === index ? "active" : ""}`}>
                      <Link to={link}>{navTab.title || <IntlMessages id={navTab.id} />}</Link>
                    </span>
                  </NavItem>
                </Fragment>
              );
            })}
          </Nav>
        </div>

        <Switch>
          {NavTabs.map((navTab, index) => {
            const link = path + _.toString(navTab.path);
            return (
              <Route
                key={index}
                exact
                path={link}
                render={() => {
                  setCurrentTab(index);
                  return <Fragment key={index}>{navTab.component}</Fragment>;
                }}
              />
            );
          })}
        </Switch>
      </Route>
    </>
  );
};

export default connect(null, {})(CustomerData);
