/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FloatLocales } from "components/special/contents";
import { setOnChange } from "components/special";
import { SwitchValue } from "components/special/forms";

import IntlMessages from "util/intlMessages";
import { adminAPI, setSuccess } from "redux/api";
import { apiUrls } from "./init";
import Select from "react-select";

const { api } = adminAPI.actions;

const JobData = (props) => {
  const { job, setJob } = props;
  const [env, setEnv] = useState({});
  const [options, setOptions] = useState({});
  const changeData = setOnChange(job, setJob);

  useEffect(() => {
    setOptions(
      _.map(env.referenceNames, (value) => {
        return { value, label: value };
      })
    );
  }, [env]);
  useEffect(() => {
    props.getEnv({}, setSuccess(setEnv));
  }, []);

  return (
    <>
      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Special.Translates.referenceName" />
        </label>
        <div className="col">
          <Select
            isMulti={false}
            value={_.filter(options, ({ value }) => value === job.referenceName)}
            options={options}
            placeholder={<IntlMessages id="Special.Translates.referenceName" />}
            onChange={({ value }) => setJob({ ...job, referenceName: value })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Special.Translates.sourceLocale" />
        </label>
        <div className="col">
          <FloatLocales
            _id={job.sourceLocaleId}
            className="btn-sm"
            showTitle={true}
            showCount={false}
            multi={false}
            successHandler={([sourceLocaleId]) => {
              setJob({ ...job, sourceLocaleId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Special.Translates.targetLocale" />
        </label>
        <div className="col">
          <FloatLocales
            _id={job.targetLocaleId}
            className="btn-sm"
            showTitle={true}
            showCount={false}
            multi={false}
            successHandler={([targetLocaleId]) => {
              setJob({ ...job, targetLocaleId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Special.Translates.skipTranslated" />
        </label>
        <div className="col-8 my-auto">
          <SwitchValue value={job.skipTranslated} name={"skipTranslated"} onChange={changeData} />
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getEnv: api.get(apiUrls.jobsEnv),
})(JobData);
