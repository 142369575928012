import { path as parentPath } from "../init";

export const path = "/translates";

export const apiUrls = {
  jobs: "/api/special/translations_jobs",
  jobsEnv: "/api/special/translations_jobs/env",
  jobInfo: "/api/special/translations_jobs/job_{jobId}",
  jobStart: "/api/special/translations_jobs/job_{jobId}/start",
  jobRestart: "/api/special/translations_jobs/job_{jobId}/restart",
};

export const jobsInfo = (job) => {
  return `${parentPath}${path}/job_${job._id}`;
};

export const jobsForm = (job) => {
  return `${jobsInfo(job)}/form`;
};
