import themeChanger from "./themeChanger/reducer";
import LanguageSwitcher from "./languageSwitcher/reducer";
import themeSetting from "./themeSettings/reducer";
import scrumboard from "./scrumboard/reducer";

import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import { adminAPIReducer } from "./api/admin";
import { filesAPIReducer } from "./api/files";
// import { partnerAPIReducer } from "./api/partner";

const createReducer = (asyncReducers) =>
  combineReducers({
    themeChanger,
    LanguageSwitcher,
    themeSetting,
    scrumboard,
    router: routerReducer,
    ...asyncReducers,
    api: adminAPIReducer,
    files: filesAPIReducer,
    // partner: partnerAPIReducer,
  });

export default createReducer;
