import _ from "lodash";
import React from "react";

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import ContentTable from "./Table";
import { PageTitle } from "components/standart";
import AddContent from "./Add";
import ContentInfo from "./Info";

const Memes = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs } = props;

  const contentInfo = (meme) => {
    return `${url}/meme_${meme._id}`;
  };

  const contentForm = (celebrity) => {
    return `${contentInfo(celebrity)}/form`;
  };

  breadCrumbs.push({
    title: <IntlMessages id="Memes" />,
    url,
    actions: [<AddContent {...{ contentForm }} />],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />

        <ContentTable {...{ contentInfo, contentForm }} />
      </Route>

      <Route path={`${path}/meme_:memeId`}>
        <ContentInfo {...{ breadCrumbs, contentInfo, contentForm }} />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

export default Memes;
